import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { Box, Typography, CircularProgress } from '@mui/material';

const workerUrl = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js';

const InvoiceDisplay = ({ invoiceData }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);

  // Create an instance of the toolbar plugin
  const toolbarPluginInstance = toolbarPlugin();

  // Transform the toolbar to include only specific functionalities
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
    const { ZoomIn, ZoomOut, GoToNextPage, GoToPreviousPage, NumberOfPages, CurrentPageInput } = slot;
    return {
      ...slot,
      ZoomIn,
      ZoomOut,
      GoToNextPage,
      GoToPreviousPage,
      NumberOfPages,
      CurrentPageInput,
    };
  };

  const { Toolbar } = toolbarPluginInstance;

  if (!invoiceData?.standardized?.documentURL) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h5">Select a document to view details</Typography>
      </Box>
    );
  }

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      height="100%"
      overflow="hidden"
      sx={{ bgcolor: '#f5f5f5' }}
    >
      <Box>
        <Toolbar>{toolbarPluginInstance.renderDefaultToolbar(transform)}</Toolbar>
      </Box>
      <Box flex={1} overflow="hidden">
        <Worker workerUrl={workerUrl}>
          <Viewer
            fileUrl={invoiceData.standardized.documentURL}
            plugins={[toolbarPluginInstance]}
            defaultScale={scale}
            onDocumentLoad={({ numPages }) => setNumPages(numPages)}
            renderLoader={(percentages) => (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            )}
          />
        </Worker>
      </Box>
    </Box>
  );
};

export default InvoiceDisplay;
