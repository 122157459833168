import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import AccountInfo from './AccountInfo';
import LinkedAccounts from './LinkedAccounts';
import SecuritySettings from './SecuritySettings';
import NotificationSnackbar from './NotificationSnackbar';

const UserSettingsTab = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState(null);

    return (
        <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
            <Typography variant="h5" gutterBottom>
                Account Settings
            </Typography>
            <Stack spacing={3}>
                <AccountInfo />
                <LinkedAccounts setSuccessMessage={setSuccessMessage} setError={setError} />
                <SecuritySettings setSuccessMessage={setSuccessMessage} setError={setError} />
            </Stack>
            <NotificationSnackbar 
                successMessage={successMessage} 
                setSuccessMessage={setSuccessMessage} 
                error={error} 
                setError={setError} 
            />
        </Box>
    );
};

export default UserSettingsTab;
