// utilities/marginUtils.js
export const formatMarginRanges = (marginRanges) => {
    return marginRanges.map(({ min, max, margin }) => ({
      min: parseFloat(min) || 0,
      max: max ? parseFloat(max) : Infinity,
      margin: parseFloat(margin) || 0
    }));
  };
  
  export const getFormattedMarginText = (marginRanges) => {
    return marginRanges
      .map(({ min, max, margin }) => {
        const maxText = max === Infinity ? 'and up' : max;
        return `$${min}-$${maxText} (${margin}%)`;
      })
      .join(', ');
  };
  