import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  CircularProgress,
  Divider,
  InputAdornment,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';

const AddSaleItems = ({ api, saleItems, setSaleItems }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // Debounced search to avoid multiple API calls
  const handleSearch = useCallback(
    debounce(async (query) => {
      if (api && query) {
        setIsSearching(true);
        try {
          const results = await api.searchSquareProducts(query);
          setSearchResults(results);
        } catch (error) {
          console.error('Error searching products:', error);
        } finally {
          setIsSearching(false);
        }
      }
    }, 300),
    [api]
  );

  const handleAddItem = (item) => {
    setSaleItems([...saleItems, { ...item, salePrice: item.price }]);
    setSearchQuery('');
    setSearchResults([]);
  };

  const handleRemoveItem = (index) => {
    setSaleItems(saleItems.filter((_, i) => i !== index));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Add Sale Items</Typography>
      
      {/* Search Bar */}
      <TextField
        fullWidth
        label="Search Products"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          handleSearch(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isSearching ? (
                <CircularProgress size={24} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
      />
      
      {/* Search Results */}
      <Box>
        {searchResults.map((item) => (
          <ListItem
            button
            key={item.variationId}
            onClick={() => handleAddItem(item)}
          >
            <ListItemAvatar>
              <Avatar src={item.imageUrls[0]} alt={item.itemName} />
            </ListItemAvatar>
            <ListItemText
              primary={item.itemName}
              secondary={`${item.variationName} - $${item.price.toFixed(2)}`}
            />
          </ListItem>
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Added Sale Items List */}
      <Typography variant="h6" gutterBottom>Selected Sale Items</Typography>
      <List>
        {saleItems.map((item, index) => (
          <ListItem
            key={item.variationId}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveItem(index)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar src={item.imageUrls[0]} alt={item.itemName} />
            </ListItemAvatar>
            <ListItemText
              primary={`${item.itemName} - ${item.variationName}`}
              secondary={`SKU: ${item.sku} | UPC: ${item.upc}`}
            />
            <TextField
              label="Sale Price"
              type="number"
              value={item.salePrice}
              onChange={(e) => {
                const updatedItems = [...saleItems];
                updatedItems[index].salePrice = parseFloat(e.target.value) || 0;
                setSaleItems(updatedItems);
              }}
              InputProps={{ inputProps: { min: 0, step: 0.01 } }}
              sx={{ width: 100, ml: 2 }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AddSaleItems;
