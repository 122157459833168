import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, List, ListItem, ListItemText,
  CircularProgress, Alert, Divider, Typography, Box, IconButton, Grid, Tabs, Tab, MenuItem, InputLabel, Select
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const SearchProductDialog = ({
  open,
  onClose,
  onSelect,
  api,
  isLoading,
  initialSearchTerm,
  currentPackSize
}) => {
  const [activeTab, setActiveTab] = useState(0);

  // Fields for searching existing products
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  // Fields for creating new product
  const [brandName, setBrandName] = useState('');
  const [itemName, setItemName] = useState('');
  const [variationName, setVariationName] = useState('');
  const [price, setPrice] = useState('');
  const [sku, setSku] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState(null);

  // Reset states when dialog closes
  useEffect(() => {
    if (!open) {
      setActiveTab(0);
      setSearchTerm(initialSearchTerm || '');
      setSearchResults([]);
      setSearchError(null);
      setIsSearching(false);

      setBrandName('');
      setItemName('');
      setVariationName('');
      setPrice('');
      setSku('');
      setCategoryId('');
      setIsCreating(false);
      setCreateError(null);
    }
  }, [open, initialSearchTerm]);

  useEffect(() => {
    setSearchTerm(initialSearchTerm || '');
  }, [initialSearchTerm]);

  // Fetch categories on mount or when `open` is true
  useEffect(() => {
    let mounted = true;
    (async () => {
      try {
        const catList = await api.getCategories();
        if (mounted) setCategories(catList || []);
      } catch (err) {
        console.warn('Failed to fetch categories:', err);
      }
    })();
    return () => { mounted = false; };
  }, [api]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Reset errors when switching tabs
    if (newValue === 0) {
      setSearchError(null);
      setSearchResults([]);
    } else {
      setCreateError(null);
    }
  };

  const handleSearch = useCallback(async () => {
    setSearchError(null);
    setSearchResults([]);
    setIsSearching(true);
    try {
      const results = await api.searchSquareProducts(searchTerm);
      setSearchResults(results);
      if (results.length === 0) {
        setSearchError('No products found. Try another search term or create a new product.');
      }
    } catch (err) {
      console.error('Error searching products:', err);
      setSearchError('Failed to search products. Please try again.');
    } finally {
      setIsSearching(false);
    }
  }, [api, searchTerm]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && activeTab === 0) {
      handleSearch();
    }
  };

  const handleSelectItem = (result) => {
    // Return product data in the expected format
    onSelect({
      id: result.productId,
      name: result.itemName,
      categoryId: result.categoryId,
      variations: [{
        id: result.variationId,
        name: result.variationName,
        price_money: {
          amount: Math.round(result.price * 100),
          currency: 'USD'
        }
      }],
      sku: result.sku,
      packSize: currentPackSize
    });
    onClose();
  };

  const validateNewProduct = () => {
    setCreateError(null);
    if (!brandName.trim() || !itemName.trim()) {
      return "Brand and item name are required. Format: 'BrandName: ItemName'";
    }
    if (!variationName.trim()) {
      return "Variation name (e.g. size) is required.";
    }
    const parsedPrice = parseFloat(price);
    if (isNaN(parsedPrice) || parsedPrice <= 0) {
      return "Price must be a positive number.";
    }
    return null;
  };

  const handleCreateProduct = async () => {
    const error = validateNewProduct();
    if (error) {
      setCreateError(error);
      return;
    }
    setIsCreating(true);
    setCreateError(null);

    const parsedPrice = parseFloat(price);
    try {
      const newProduct = await api.createSquareProduct({
        brandName: brandName.trim(),
        itemName: itemName.trim(),
        variationName: variationName.trim(),
        price: parsedPrice,
        sku: sku.trim(),
        categoryId: categoryId.trim()
      });
      // Once created, select it like a search result
      handleSelectItem({
        productId: newProduct.itemId,
        itemName: newProduct.itemName,
        variationName: newProduct.variationName,
        variationId: newProduct.variationId,
        price: newProduct.price,
        sku: newProduct.sku,
        categoryId: newProduct.categoryId
      });
    } catch (err) {
      console.error('Error creating product:', err);
      setCreateError('Failed to create product. Please try again.');
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Select or Create a Product</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Divider />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
          <Tab label="Search Existing Products" />
          <Tab label="Create New Product" />
        </Tabs>
      </Box>

      <DialogContent sx={{ mt: 2 }}>
        {activeTab === 0 && (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Search for an existing product in your Square catalog, or switch to "Create New Product" to add one if none matches.
            </Typography>

            <Box display="flex" alignItems="center" mb={2} gap={1}>
              <TextField
                label="Search Square Products"
                fullWidth
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <IconButton onClick={handleSearch} color="primary" disabled={isSearching || isLoading}>
                {isSearching ? <CircularProgress size={24} /> : <SearchIcon />}
              </IconButton>
            </Box>

            {searchError && <Alert severity="error" sx={{ mb: 2 }}>{searchError}</Alert>}

            <List sx={{ maxHeight: 400, overflow: 'auto', border: '1px solid #ccc', borderRadius: 1 }}>
              {searchResults.map((result) => (
                <React.Fragment key={`${result.productId}-${result.variationId}`}>
                  <ListItem button onClick={() => handleSelectItem(result)}>
                    <ListItemText
                      primary={<Typography variant="subtitle1">{`${result.itemName} - ${result.variationName}`}</Typography>}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="textPrimary">Price: ${result.price.toFixed(2)}</Typography>
                          {result.sku && <>
                            <br />
                            <Typography component="span" variant="body2" color="textSecondary">SKU: {result.sku}</Typography>
                          </>}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
              {!isSearching && searchResults.length === 0 && !searchError && (
                <ListItem>
                  <ListItemText primary="No results found." />
                </ListItem>
              )}
            </List>
          </>
        )}

        {activeTab === 1 && (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Create a new product in Square. Required fields: BrandName, ItemName, Variation, Price.
              <br />
              Format for Product Name: "BrandName: ItemName".
              <br />
              If categories are available, select one. Otherwise, you may leave it blank or enter a custom category name.
            </Typography>

            {createError && <Alert severity="error" sx={{ mb: 2 }}>{createError}</Alert>}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Brand Name"
                  fullWidth
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                  helperText="Required"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Item Name"
                  fullWidth
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  helperText='Required. Final name: "BrandName: ItemName"'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Variation (e.g. size)"
                  fullWidth
                  value={variationName}
                  onChange={(e) => setVariationName(e.target.value)}
                  helperText="Required"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Price (USD)"
                  fullWidth
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  helperText="Required. Must be > 0"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="SKU"
                  fullWidth
                  value={sku}
                  onChange={(e) => setSku(e.target.value)}
                  helperText="Optional"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {categories.length > 0 ? (
                  <>
                    <InputLabel id="category-label">Category (Optional)</InputLabel>
                    <Select
                      labelId="category-label"
                      fullWidth
                      value={categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="">None</MenuItem>
                      {categories.map((cat) => (
                        // Use cat.squareId as the value, not cat.name
                        <MenuItem key={cat.squareId} value={cat.squareId}>{cat.name}</MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <TextField
                    label="Category (Optional)"
                    fullWidth
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                    helperText="No categories found. Enter a category name if desired, or leave blank."
                  />
                )}

              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        {activeTab === 0 ? (
          <Button onClick={onClose} color="primary">Done</Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateProduct}
            startIcon={isCreating ? <CircularProgress size={20} /> : <AddIcon />}
            disabled={isCreating}
          >
            Create Product
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(SearchProductDialog);
