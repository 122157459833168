// AllInvoicesPage.js
import React, { memo, Suspense, useEffect } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Alert,
  Stack,
  Typography,
  Container
} from '@mui/material';
import SearchFilterSection from './SearchFilterSection';
import InvoiceList from './InvoiceTable';
import { useInvoiceLogic } from './useInvoiceLogic';
import { invoicePageStyles } from './styles';
import { FILTER_OPTIONS } from './constants';
import ErrorBoundary from '../ErrorBoundary';

const LoadingState = memo(() => (
  <Box sx={invoicePageStyles.loadingContainer}>
    <CircularProgress />
  </Box>
));

const ErrorState = memo(({ message = 'Failed to load invoices' }) => (
  <Box sx={invoicePageStyles.errorContainer}>
    <Alert severity="error">{message}</Alert>
  </Box>
));

const PageHeader = memo(({ title, subtitle }) => {
  console.log('PageHeader render:', { title, subtitle });
  return (
    <Box sx={invoicePageStyles.header}>
      <Typography variant="h5" component="h1">
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle2" color="text.secondary">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
});

const InvoiceContent = memo(({
  searchQuery,
  filterStatus,
  filteredData,
  handlers,
  sortConfig,
  onSort
}) => {
  console.log('InvoiceContent render:', {
    searchQuery,
    filterStatus,
    filteredDataExists: !!filteredData,
    handlersExist: {
      searchChange: !!handlers?.handleSearchChange,
      filterChange: !!handlers?.handleFilterChange,
      rowClick: !!handlers?.handleRowClick
    }
  });

  const {
    paginatedInvoices,
    sortedInvoices
  } = filteredData || {};

  const {
    handleSearchChange,
    handleFilterChange,
    handleRowClick,
    handleSort
  } = handlers || {};

  return (
    <Stack spacing={3}>
      <SearchFilterSection
        searchQuery={searchQuery}
        filterStatus={filterStatus}
        onSearchChange={handleSearchChange}
        onFilterChange={handleFilterChange}
      />

      <InvoiceList
        invoices={paginatedInvoices || []}
        totalInvoices={(sortedInvoices || []).length}
        onRowClick={handleRowClick}
        onSort={handleSort}
        sortConfig={sortConfig}
      />
    </Stack>
  );
});

const AllInvoicesPage = () => {
  const { invoices = [] } = useOutletContext();
  console.log('AllInvoicesPage render:', { 
    hasInvoices: !!invoices,
    invoicesLength: invoices?.length 
  });

  const invoiceLogic = useInvoiceLogic(invoices);
  
  console.log('InvoiceLogic state:', {
    searchQuery: invoiceLogic?.searchQuery,
    filterStatus: invoiceLogic?.filterStatus,
    hasFilteredData: !!invoiceLogic?.filteredData,
    hasHandlers: !!invoiceLogic?.handlers,
    paginatedInvoicesLength: invoiceLogic?.filteredData?.paginatedInvoices?.length,
    sortedInvoicesLength: invoiceLogic?.filteredData?.sortedInvoices?.length
  });

  useEffect(() => {
    console.log('InvoiceLogic updated:', invoiceLogic);
  }, [invoiceLogic]);

  if (!invoices) {
    console.log('No invoices data');
    return <LoadingState />;
  }

  if (!Array.isArray(invoices)) {
    console.log('Invoices is not an array');
    return <ErrorState />;
  }

  return (
    <ErrorBoundary fallback={<ErrorState message="Something went wrong" />}>
      <Suspense fallback={<LoadingState />}>
          <PageHeader
            title="Invoices"
            subtitle={
              invoices.length > 0
                ? `Managing ${invoices.length} invoices`
                : 'No invoices found'
            }
          />

          {invoices.length === 0 ? (
            <ErrorState message="No invoices found. New invoices will appear here." />
          ) : (
            <InvoiceContent 
              searchQuery={invoiceLogic.searchQuery}
              filterStatus={invoiceLogic.filterStatus}
              filteredData={invoiceLogic.filteredData}
              handlers={invoiceLogic.handlers}
            />
          )}

          <Outlet />
      </Suspense>
    </ErrorBoundary>
  );
};

// Add display names for better debugging
PageHeader.displayName = 'PageHeader';
InvoiceContent.displayName = 'InvoiceContent';
LoadingState.displayName = 'LoadingState';
ErrorState.displayName = 'ErrorState';
AllInvoicesPage.displayName = 'AllInvoicesPage';

export default memo(AllInvoicesPage);