import React, { useState, useCallback } from 'react';
import { 
    Avatar, 
    CircularProgress, 
    Box, 
    Tooltip
} from '@mui/material';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase';
import { getAuth, updateProfile } from 'firebase/auth';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

const ProfilePictureUpload = ({ setSuccessMessage, setError }) => {
    const auth = getAuth();
    const [photoURL, setPhotoURL] = useState(auth.currentUser?.photoURL || '');
    const [isUploading, setIsUploading] = useState(false);

    const validateFile = (file) => {
        if (!file) return 'Please select a file';
        if (!ALLOWED_TYPES.includes(file.type)) {
            return 'Please upload a JPEG, PNG, or WebP image';
        }
        if (file.size > MAX_FILE_SIZE) {
            return 'File size should be less than 5MB';
        }
        return null;
    };

    const compressImage = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const MAX_WIDTH = 800;
                    const scaleSize = MAX_WIDTH / img.width;
                    canvas.width = MAX_WIDTH;
                    canvas.height = img.height * scaleSize;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    
                    canvas.toBlob(
                        (blob) => resolve(blob),
                        'image/jpeg',
                        0.8
                    );
                };
            };
        });
    };

    const handlePhotoUpload = useCallback(async (event) => {
        const file = event.target.files[0];
        const errorMessage = validateFile(file);
        
        if (errorMessage) {
            setError(errorMessage);
            return;
        }

        setIsUploading(true);

        try {
            const compressedImage = await compressImage(file);
            const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
            await uploadBytes(storageRef, compressedImage);
            const downloadURL = await getDownloadURL(storageRef);
            
            await updateProfile(auth.currentUser, { photoURL: downloadURL });
            setPhotoURL(downloadURL);
            setSuccessMessage('Profile picture updated successfully!');
        } catch (err) {
            console.error('Upload error:', err);
            setError(err.message || 'Failed to upload profile picture.');
        } finally {
            setIsUploading(false);
        }
    }, [auth.currentUser, setError, setSuccessMessage]);

    return (
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
            <Tooltip title={`Upload photo (JPEG, PNG, WebP - max 5MB)`}>
                <Box component="label" sx={{ cursor: 'pointer' }}>
                    <input 
                        type="file"
                        hidden 
                        accept={ALLOWED_TYPES.join(',')}
                        onChange={handlePhotoUpload}
                        disabled={isUploading}
                    />
                    <Avatar 
                        src={photoURL} 
                        sx={{ 
                            width: 120, 
                            height: 120, 
                            mx: 'auto',
                            border: '3px solid',
                            borderColor: 'primary.main',
                            '&:hover': {
                                opacity: 0.8,
                                cursor: 'pointer'
                            }
                        }} 
                    >
                        {isUploading && (
                            <CircularProgress 
                                size={60}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-30px',
                                    marginLeft: '-30px'
                                }}
                            />
                        )}
                    </Avatar>
                </Box>
            </Tooltip>
        </Box>
    );
};

export default ProfilePictureUpload;