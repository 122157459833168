import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, query, orderBy, limit, startAfter, onSnapshot } from 'firebase/firestore';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, CircularProgress, Typography
} from '@mui/material';

const Actions = () => {
    const [actions, setActions] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [totalActions, setTotalActions] = useState(-1); // Placeholder for estimated count


    useEffect(() => {
        // Local unsubscribe reference
        let unsubscribe;
    
        const fetchActions = async () => {
            try {
                setLoading(true);
                const q = query(
                    collection(db, 'ActionLogs'),
                    orderBy('timestamp', 'desc'),
                    limit(rowsPerPage)
                );
    
                // Assign the unsubscribe function directly
                unsubscribe = onSnapshot(q, (snapshot) => {
                    const actionsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setActions(actionsData);
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                    setLoading(false);
                });
            } catch (error) {
                console.error("Error fetching actions: ", error);
                setLoading(false);
            }
        };
    
        fetchActions();
    
        // Cleanup function
        return () => {
            if (unsubscribe) {
                unsubscribe(); // Call unsubscribe when the component unmounts
            }
        };
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // Use fetchActions to fetch the next or previous page based on newPage logic
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredActions = actions.filter((action) =>
        (action.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (action.endpoint?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (action.method?.toLowerCase() || '').includes(searchTerm.toLowerCase())
    );

    return (
        <Paper sx={{ padding: 2 }}>
            <TextField
                label="Search Actions"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleSearch}
            />
            {loading ? (
                <CircularProgress />
            ) : filteredActions.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User ID</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Display Name</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Endpoint</TableCell>
                                <TableCell>Method</TableCell>
                                <TableCell>Timestamp</TableCell>
                                <TableCell>User Agent</TableCell>
                                <TableCell>Customer ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredActions.map((action) => (
                                <TableRow key={action.id}>
                                    <TableCell>{action.userId}</TableCell>
                                    <TableCell>{action.email || 'N/A'}</TableCell>
                                    <TableCell>{action.displayName || 'N/A'}</TableCell>
                                    <TableCell>{action.phoneNumber || 'N/A'}</TableCell>
                                    <TableCell>{action.endpoint || 'N/A'}</TableCell>
                                    <TableCell>{action.method || 'N/A'}</TableCell>
                                    <TableCell>{new Date(action.timestamp).toLocaleString()}</TableCell>
                                    <TableCell>{action.userAgent || 'N/A'}</TableCell>
                                    <TableCell>{action.customerId || 'N/A'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
                    No actions found.
                </Typography>
            )}
            <TablePagination
                component="div"
                count={totalActions} // Placeholder for now, can be calculated using another query
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Rows per page"
            />
        </Paper>
    );
};

export default Actions;
