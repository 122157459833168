import React, { useState } from 'react';
import { Grid, Switch, FormControlLabel, Button, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

const firestore = getFirestore();

const GeneralSettings = ({ onError, onSuccess }) => {
  const [settings, setSettings] = useState({
    autoProcessInvoices: true,
    enableNotifications: true,
  });
  const [saving, setSaving] = useState(false);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setSettings((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const companyId = 'mock-company-id'; // Replace with actual companyId
      await updateDoc(doc(firestore, 'companies', companyId), {
        settings,
        updatedAt: new Date(),
      });
      onSuccess('Settings saved successfully');
    } catch (error) {
      onError(`Error saving settings: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.autoProcessInvoices}
                onChange={handleChange}
                name="autoProcessInvoices"
              />
            }
            label="Automatically process invoices"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.enableNotifications}
                onChange={handleChange}
                name="enableNotifications"
              />
            }
            label="Enable email notifications"
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={saving}
        startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
        sx={{ mt: 2 }}
      >
        Save Changes
      </Button>
    </form>
  );
};

export default GeneralSettings;
