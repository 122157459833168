import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Tooltip,
  IconButton,
  Card,
  CardMedia,
  CardActions,
  Snackbar,
  Alert,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * ProductField Component - A reusable form field component for product details
 * @param {Object} props - Component props
 * @param {string} props.label - Field label
 * @param {string|number} props.value - Field value
 * @param {Function} props.onChange - Change handler function
 * @param {string} [props.type='text'] - Input type
 * @param {boolean} [props.disabled=false] - Disabled state
 */
const ProductField = ({ label, value, onChange, type = 'text', disabled = false }) => (
  <Grid item xs={12} md={6}>
    <TextField
      label={label}
      fullWidth
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      type={type}
      variant="outlined"
      margin="dense"
      disabled={disabled}
    />
  </Grid>
);

/**
 * ProductDetails Component - Manages the display and editing of product information
 */
const ProductDetails = () => {
  // Router and state management
  const { state } = useLocation();
  const navigate = useNavigate();
  
  // State management for product data and UI elements
  const [editableProduct, setEditableProduct] = useState(state?.product || {});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);

  /**
   * Handles changes to product fields
   * @param {string} field - Field name to update
   * @param {string|number} value - New value for the field
   */
  const handleInputChange = (field, value) => {
    setEditableProduct((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  /**
   * Handles product save operation
   */
  const handleSave = () => {
    // TODO: Implement actual save logic to backend
    console.log('Product saved:', editableProduct);
    setSnackbarOpen(true);
  };

  /**
   * Handles image upload and preview
   * @param {Event} event - File input change event
   */
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview((prev) => [...prev, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  /**
   * Handles closing the success snackbar
   */
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Render product images section
  const renderProductImages = () => (
    <Grid item xs={12}>
      <Typography variant="body1" gutterBottom>
        Product Images:
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        {/* Display existing or newly uploaded images */}
        {(imagePreview.length ? imagePreview : editableProduct.imageUrls || []).map(
          (url, index) => (
            <CardMedia
              key={index}
              component="img"
              height="140"
              image={url}
              alt={`Product Image ${index}`}
              sx={{ width: 'auto', maxWidth: '150px' }}
            />
          )
        )}
        {/* Image upload button */}
        <Tooltip title="Upload New Image">
          <IconButton component="label">
            <UploadIcon />
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Grid>
  );

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Product Details
      </Typography>

      <Card sx={{ mb: 4, padding: 3 }}>
        <Grid container spacing={3}>
          {/* Product Information Fields */}
          <ProductField
            label="Product Name"
            value={editableProduct.itemName}
            onChange={(value) => handleInputChange('itemName', value)}
          />
          <ProductField
            label="SKU"
            value={editableProduct.sku}
            onChange={(value) => handleInputChange('sku', value)}
          />
          <ProductField
            label="Price"
            value={editableProduct.price}
            onChange={(value) => handleInputChange('price', value)}
            type="number"
          />
          <ProductField
            label="Stock Level"
            value={editableProduct.stockLevel}
            onChange={(value) => handleInputChange('stockLevel', value)}
            type="number"
          />
          <ProductField
            label="Reorder Threshold"
            value={editableProduct.threshold}
            onChange={(value) => handleInputChange('threshold', value)}
            type="number"
          />
          <ProductField
            label="Category"
            value={editableProduct.categoryName || 'N/A'}
            disabled
          />

          {/* Product Images Section */}
          {renderProductImages()}
        </Grid>

        {/* Action Buttons */}
        <CardActions sx={{ justifyContent: 'space-between', mt: 3 }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => navigate(-1)}
          >
            Back to Search
          </Button>
        </CardActions>
      </Card>

      {/* Success Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Product saved successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProductDetails;