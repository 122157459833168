import React, { useEffect, useState, useMemo } from 'react';
import createApi from './inventoryApi';
import { useAuth } from '../../context/AuthContext';
import {
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Alert,
    Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-head': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

const Inventory = () => {
    const [vendors, setVendors] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { currentUser } = useAuth();

    const api = useMemo(() => {
        if (currentUser) {
            return createApi(currentUser.stsTokenManager.accessToken);
        }
        return null;
    }, [currentUser]);

    useEffect(() => {
        if (!api) return;

        const fetchData = async () => {
            try {
                setLoading(true);
                const vendorResponse = await api.getAllVendors();
                const productResponse = await api.getAllProducts(true);
                setVendors(vendorResponse.data.vendors);
                setProducts(productResponse.data.products);
            } catch (err) {
                console.error('Error loading data:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [api]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Alert severity="error">Error: {error}</Alert>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Inventory Management
            </Typography>

            {/* Vendors Section */}
            <Paper elevation={3} sx={{ mb: 4, p: 2 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Vendors
                </Typography>
                <Grid container spacing={3}>
                    {vendors.map((vendor) => (
                        <Grid item xs={12} md={6} key={vendor.vendorid}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h3">
                                        {vendor.name}
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        {vendor.address}
                                    </Typography>
                                    <Typography variant="body2">
                                        Contact: {vendor.contactperson}
                                    </Typography>
                                    <Typography variant="body2">
                                        Phone: {vendor.phone}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Paper>

            {/* Products Section */}
            <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Products
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>SKU</StyledTableCell>
                                <StyledTableCell>Category</StyledTableCell>
                                <StyledTableCell align="right">Price</StyledTableCell>
                                <StyledTableCell align="right">Cost</StyledTableCell>
                                <StyledTableCell>Barcode</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((product) => (
                                <TableRow key={product.productid}>
                                    <TableCell>
                                        <Typography variant="subtitle2">{product.name}</Typography>
                                    </TableCell>
                                    <TableCell>{product.sku}</TableCell>
                                    <TableCell>
                                        <Chip
                                            label={`${product.category} / ${product.parentcategory}`}
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        ${typeof product.unitprice === 'number'
                                            ? product.unitprice.toFixed(2)
                                            : parseFloat(product.unitprice).toFixed(2) || '0.00'}
                                    </TableCell>
                                    <TableCell align="right">
                                        ${typeof product.costprice === 'number'
                                            ? product.costprice.toFixed(2)
                                            : parseFloat(product.costprice).toFixed(2) || '0.00'}
                                    </TableCell>
                                    <TableCell>{product.barcode}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
};

export default Inventory;