import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  TextField,
  Button,
  Paper,
  Grid,
  Stack,
  Container,
  IconButton,
  Tooltip
} from '@mui/material';
import { Refresh as RefreshIcon, Sync as SyncIcon, Search as SearchIcon } from '@mui/icons-material';
import createApi from '../api';
import CategoryList from './CategoryList';
import UpdateMarginRanges from './UpdateMarginRanges';
import useDialog from './useDialog';
import useDebouncedValue from './useDebouncedValue';
import Loader from './Loader';
import { formatMarginRanges } from './marginUtils';

const CategoryView = ({ currentUser }) => {
  // State Management
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [marginRanges, setMarginRanges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);
  const { open: dialogOpen, openDialog, closeDialog } = useDialog();
  const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), 
    [currentUser?.stsTokenManager?.accessToken]
  );

  // API Calls
  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await api.getCategories();
      const formattedData = data.map(category => ({
        ...category,
        marginRanges: formatMarginRanges(category.marginRanges || [])
      }));
      setCategories(formattedData);
      setFilteredCategories(formattedData);
    } catch (err) {
      setError('Failed to fetch categories. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const syncCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.syncCategories();
      setSuccess('Categories synchronized successfully');
      await fetchCategories();
    } catch (err) {
      setError('Failed to sync categories. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [api, fetchCategories]);

  // Effects
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    const filtered = debouncedSearchTerm
      ? categories.filter(category =>
          category.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        )
      : categories;
    setFilteredCategories(filtered);
  }, [debouncedSearchTerm, categories]);

  // Event Handlers
  const handleCategoryClick = useCallback((category) => {
    setSelectedCategory(category);
    setMarginRanges(
      category.marginRanges.length > 0 
        ? category.marginRanges 
        : [{ min: 0, max: '', margin: 0 }]
    );
    openDialog();
  }, [openDialog]);

  const handleDialogClose = useCallback(() => {
    closeDialog();
    setSelectedCategory(null);
    setMarginRanges([]);
  }, [closeDialog]);

  const handleSave = async (updatedRanges) => {
    setIsLoading(true);
    try {
      await api.updateCategoryMarginRange(selectedCategory.squareId, updatedRanges);
      setSuccess('Margin ranges updated successfully');
      handleDialogClose();
      await fetchCategories();
    } catch (err) {
      setError('Failed to update margin ranges. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center" 
              spacing={2}
            >
              <Typography variant="h4" component="h1">
                Categories Management
              </Typography>
              <Stack direction="row" spacing={2}>
                <Tooltip title="Sync Categories">
                  <IconButton 
                    color="primary" 
                    onClick={syncCategories} 
                    disabled={isLoading}
                  >
                    <SyncIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Search Categories"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
              }}
              sx={{ mb: 3 }}
            />
          </Grid>

          <Grid item xs={12}>
            {isLoading ? (
              <Box display="flex" justifyContent="center" p={4}>
                <Loader />
              </Box>
            ) : (
              <CategoryList 
                categories={filteredCategories}
                onCategoryClick={handleCategoryClick}
              />
            )}
          </Grid>
        </Grid>
      </Paper>

      <UpdateMarginRanges
        open={dialogOpen}
        onClose={handleDialogClose}
        category={selectedCategory}
        initialMarginRanges={marginRanges}
        onSave={handleSave}
        isLoading={isLoading}
      />

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(null)} severity="error" variant="filled">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!success} 
        autoHideDuration={6000} 
        onClose={() => setSuccess(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSuccess(null)} severity="success" variant="filled">
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CategoryView;