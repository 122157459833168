import axios from 'axios';

const createApi = (token) => {
  const api = axios.create({
    baseURL: 'https://api.fivespiceindiangrocery.com/api',
    headers: {
      Authorization: `Bearer ${token}` // Standard format for Bearer token
    }
  });

  return {
    // Product Endpoints
    createProduct: (productData) => api.post('/createProduct', productData),
    updateProduct: (productId, updatedFields) => api.put(`/updateProduct/${productId}`, updatedFields),
    deleteProduct: (productId) => api.delete(`/deleteProduct/${productId}`),
    getAllProducts: (includeVendorProducts = false) =>
      api.get('/getAllProducts', { params: { includeVendorProducts } }),
    getProductById: (productId, includeVendorInfo = false) =>
      api.get(`/getProduct/${productId}`, { params: { includeVendorInfo } }),

    // Vendor Endpoints
    createVendor: (vendorData) => api.post('/createVendor', vendorData),
    updateVendor: (vendorId, updatedFields) => api.put(`/updateVendor/${vendorId}`, updatedFields),
    deleteVendor: (vendorId) => api.delete(`/deleteVendor/${vendorId}`),
    getAllVendors: () => api.get('/getAllVendors'),
    getVendorById: (vendorId) => api.get(`/getVendor/${vendorId}`)
  };
};

export default createApi;
