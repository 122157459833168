import React, { useState, useEffect } from 'react';
import { Typography, Button, Box, CircularProgress } from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';

const Integrations = ({ currentUser, onError, onSuccess }) => {
  const [squareConnected, setSquareConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('Integrations component mounted, checking Square connection...');

    const checkConnection = async () => {
      console.log('Checking Square connection status...');
      try {
        const idToken = await currentUser.getIdToken();
        console.log('ID token retrieved:', idToken);

        const response = await fetch('/api/square/auth-status', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const data = await response.json();
        console.log('Square connection status response:', data);
        setSquareConnected(data.isAuthenticated);
      } catch (error) {
        console.error('Error checking Square connection:', error);
        onError('Error checking Square connection');
      }
    };

    checkConnection();
  }, [currentUser, onError]);

  useEffect(() => {
    // Log current URL parameters
    console.log('URL parameters:', window.location.search);

    const query = new URLSearchParams(window.location.search);
    const status = query.get('status');
    console.log('Status query parameter:', status);

    if (status === 'success') {
      onSuccess('Square connected successfully!');
      setSquareConnected(true);
    } else if (status === 'error') {
      onError('Failed to connect to Square.');
    }
  }, [onSuccess, onError]);

  const handleConnect = () => {
    console.log('Square connect button clicked');
    const redirectUri = `${window.location.origin}/oauth/square-callback`;
    console.log('Redirect URI:', redirectUri);
    const oauthUrl = `https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb-1zMWWkBkcslDsZPU51LvVQ&scope=MERCHANT_PROFILE_READ&redirect_uri=${redirectUri}`;
    console.log('OAuth URL:', oauthUrl);
    window.location.href = oauthUrl;
  };

  return (
    <Box>
      <Typography variant="h6">Point of Sale Integrations</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <Typography>Square</Typography>
        {squareConnected ? (
          <Button variant="outlined" startIcon={<RefreshIcon />} onClick={handleConnect}>
            Connected
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={loading ? <CircularProgress size={20} /> : <AddIcon />}
            onClick={handleConnect}
          >
            Connect
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Integrations;
