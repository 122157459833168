// InvoiceList.js
import React, { memo, useMemo } from 'react';
import {
    Box,
    Paper,
    Typography,
    IconButton,
    Stack,
    Divider,
    Tooltip,
    Chip
} from '@mui/material';
import {
    ArrowForward,
    CalendarToday,
    TrendingUp,
    Assessment,
    Warning,
    ArrowUpward,
    ArrowDownward
} from '@mui/icons-material';

// Status configuration for consistent styling
const STATUS_CONFIG = {
    pending: {
        color: 'warning',
        icon: '⏳',
        label: 'Pending',
        backgroundColor: 'warning.lighter'
    },
    done: {
        color: 'success',
        icon: '✓',
        label: 'Completed',
        backgroundColor: 'success.lighter'
    },
    reported: {
        color: 'info',
        icon: '📊',
        label: 'Reported',
        backgroundColor: 'info.lighter'
    },
    new: {
        color: 'primary',
        icon: '🆕',
        label: 'New',
        backgroundColor: 'primary.lighter'
    }
};

const TableHeader = memo(({ onSort, sortConfig = { field: null, direction: 'asc' } }) => {
    const SortIndicator = ({ field }) => (
        sortConfig.field === field && (
            sortConfig.direction === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />
        )
    );

    return (
        <Paper
            elevation={0}
            sx={{
                p: 2,
                mb: 1,
                backgroundColor: 'background.paper',
                borderBottom: 1,
                borderColor: 'divider'
            }}
        >
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={3} alignItems="center" sx={{ flex: 1 }}>
                    <Box sx={{ minWidth: '100px' }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                '&:hover': { color: 'primary.main' }
                            }}
                            onClick={() => onSort('invoiceDate')}
                        >
                            Date <SortIndicator field="invoiceDate" />
                        </Typography>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                '&:hover': { color: 'primary.main' }
                            }}
                            onClick={() => onSort('vendorName')}
                        >
                            Vendor <SortIndicator field="vendorName" />
                        </Typography>
                    </Box>
                </Stack>

                <Stack direction="row" spacing={3} alignItems="center">
                    <Box sx={{ minWidth: '100px' }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                '&:hover': { color: 'primary.main' }
                            }}
                            onClick={() => onSort('invoiceStatus')}
                        >
                            Status <SortIndicator field="invoiceStatus" />
                        </Typography>
                    </Box>

                    <Box sx={{ minWidth: '90px' }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                textAlign: 'right',
                                '&:hover': { color: 'primary.main' }
                            }}
                            onClick={() => onSort('invoiceTotal')}
                        >
                            Amount <SortIndicator field="invoiceTotal" />
                        </Typography>
                    </Box>

                    <Box sx={{ width: 40 }} /> {/* Spacer for action button */}
                </Stack>
            </Stack>
        </Paper>
    );
});

const StatusChip = memo(({ status }) => {
    const config = STATUS_CONFIG[status] || STATUS_CONFIG.pending;

    return (
        <Chip
            size="small"
            label={config.label}
            icon={<span>{config.icon}</span>}
            sx={{
                backgroundColor: config.backgroundColor,
                color: `${config.color}.main`,
                minWidth: '100px'
            }}
        />
    );
});
const InvoiceRow = memo(({ invoice, onRowClick }) => (
    <Paper
        elevation={0}
        sx={{
            p: 2,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&:hover': {
                backgroundColor: 'action.hover',
                cursor: 'pointer'
            },
            borderRadius: '4px'
        }}
        onClick={() => onRowClick(invoice)}
    >
        <Stack direction="row" spacing={3} alignItems="center" sx={{ flex: 1 }}>
            <Box sx={{ minWidth: '100px' }}>
                <Typography variant="body2" color="text.secondary">
                    <CalendarToday fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                    {(() => {
                        try {
                            return invoice.invoiceDate ?
                                new Date(invoice.invoiceDate.replace(/-/g, '/')).toLocaleDateString('en-CA')
                                : 'No date';
                        } catch (error) {
                            console.error('Date parsing error:', error);
                            return 'Invalid date';
                        }
                    })()}
                </Typography>
            </Box>

            <Stack sx={{ flex: 1 }}>
                <Typography variant="subtitle1" noWrap>
                    {invoice.vendorName || 'Unknown Vendor'}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    #{invoice.invoiceNumber || 'No Invoice Number'}
                </Typography>
            </Stack>
        </Stack>

        <Stack direction="row" spacing={3} alignItems="center">
            <StatusChip status={invoice.invoiceStatus} />

            <Typography
                variant="subtitle1"
                sx={{
                    color: 'success.main',
                    minWidth: '90px',
                    textAlign: 'right'
                }}
            >
                $ {parseFloat(invoice.invoiceTotal || 0).toFixed(2)}
            </Typography>

            <Tooltip title="View Details">
                <IconButton
                    size="small"
                    sx={{
                        color: 'text.secondary',
                        '&:hover': { color: 'primary.main' }
                    }}
                >
                    <ArrowForward fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    </Paper>
));

const InvoiceSummary = memo(({ statistics }) => (
    <Paper
        elevation={0}
        sx={{
            p: 2,
            mb: 3,
            backgroundColor: 'primary.light',
            color: 'primary.contrastText',
            borderRadius: '8px'
        }}
    >
        <Stack direction="row" spacing={2} justifyContent="space-between">
            <Box sx={{ flex: 1, textAlign: 'center' }}>
                <Typography variant="h6">Total Value</Typography>
                <Typography variant="h4">${statistics.totalValue.toFixed(2)}</Typography>
                <Typography variant="caption">
                    Pending: ${statistics.pendingValue.toFixed(2)}
                </Typography>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ bgcolor: 'primary.contrastText', opacity: 0.2 }} />
            <Box sx={{ flex: 1, textAlign: 'center' }}>
                <Typography variant="h6">Invoices</Typography>
                <Typography variant="h4">{statistics.totalCount}</Typography>
                <Typography variant="caption">
                    Pending: {statistics.pendingCount}
                </Typography>
            </Box>
        </Stack>
    </Paper>
));

const InvoiceList = ({ 
    invoices = [], 
    onRowClick,
    onSort,
    sortConfig = { field: 'invoiceDate', direction: 'desc' }
}) => {
    const statistics = useMemo(() => {
        const statusBreakdown = invoices.reduce((acc, inv) => {
            acc[inv.invoiceStatus] = (acc[inv.invoiceStatus] || 0) + 1;
            return acc;
        }, {});

        const totalValue = invoices.reduce((sum, inv) => sum + parseFloat(inv.invoiceTotal || 0), 0);
        const pendingInvoices = invoices.filter(inv => inv.invoiceStatus === 'pending');
        const pendingValue = pendingInvoices.reduce((sum, inv) => sum + parseFloat(inv.invoiceTotal || 0), 0);
        const averageInvoiceValue = totalValue / (invoices.length || 1);

        return {
            totalValue,
            pendingValue,
            pendingCount: pendingInvoices.length,
            totalCount: invoices.length,
            averageInvoiceValue,
            statusBreakdown
        };
    }, [invoices]);

    return (
        <Box sx={{
            maxWidth: '100%',
            px: 3,
            py: 2,
            height: '100%',
            overflow: 'auto'
        }}>
            <TableHeader onSort={onSort} sortConfig={sortConfig} />
            
            <Stack spacing={1}>
                {invoices.length > 0 ? (
                    invoices.map(invoice => (
                        <InvoiceRow
                            key={invoice.doc_id}
                            invoice={invoice}
                            onRowClick={onRowClick}
                        />
                    ))
                ) : (
                    <Paper
                        elevation={0}
                        sx={{
                            p: 4,
                            textAlign: 'center',
                            backgroundColor: 'background.paper'
                        }}
                    >
                        <Typography variant="h6" color="text.secondary">
                            No invoices found
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Try adjusting your search or filter criteria
                        </Typography>
                    </Paper>
                )}
            </Stack>
        </Box>
    );
};

// Add display names for better debugging
TableHeader.displayName = 'TableHeader';
StatusChip.displayName = 'StatusChip';
InvoiceRow.displayName = 'InvoiceRow';
InvoiceSummary.displayName = 'InvoiceSummary';
InvoiceList.displayName = 'InvoiceList';

export default memo(InvoiceList);