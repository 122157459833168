import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Divider,
  Typography,
  Box,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Add as AddCircleIcon } from '@mui/icons-material';

function CreateNewProductDialog({ open, onClose, onCreateProduct, isLoading }) {
  const [productDetails, setProductDetails] = useState({
    name: '',
    variationName: '',
    price: '',
    sku: '',
    category: '',
  });
  const [error, setError] = useState(null);

  const handleChange = (field, value) => {
    setProductDetails(prev => ({ ...prev, [field]: value }));
  };

  const handleCreate = async () => {
    setError(null);
    // Validate inputs
    if (!productDetails.name || !productDetails.variationName || !productDetails.price) {
      setError('Please fill out required fields: Product Name, Variation Name, and Price.');
      return;
    }

    const parsedPrice = parseFloat(productDetails.price);
    if (isNaN(parsedPrice) || parsedPrice <= 0) {
      setError('Price must be a positive number.');
      return;
    }

    try {
      const newProduct = await onCreateProduct({
        name: productDetails.name,
        variationName: productDetails.variationName,
        price: parsedPrice,
        sku: productDetails.sku || '',
        category: productDetails.category || '',
      });
      // On success, close dialog
      onClose(newProduct);
    } catch (err) {
      console.error('Error creating product:', err);
      setError('Failed to create product. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(null)} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Create New Product in Square</Typography>
          <IconButton edge="end" color="inherit" onClick={() => onClose(null)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <TextField
              label="Product Name *"
              fullWidth
              value={productDetails.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Variation Name *"
              fullWidth
              value={productDetails.variationName}
              onChange={(e) => handleChange('variationName', e.target.value)}
              helperText="e.g. Single Unit, Case of 12, etc."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Price *"
              fullWidth
              type="number"
              value={productDetails.price}
              onChange={(e) => handleChange('price', e.target.value)}
              InputProps={{ startAdornment: <Box mr={0.5}>$</Box> }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="SKU"
              fullWidth
              value={productDetails.sku}
              onChange={(e) => handleChange('sku', e.target.value)}
              helperText="Optional"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Category"
              fullWidth
              value={productDetails.category}
              onChange={(e) => handleChange('category', e.target.value)}
              helperText="Optional"
            />
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary">
          Once created, this product will be available in your Square catalog.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(null)}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={isLoading ? <CircularProgress size={20} /> : <AddCircleIcon />}
          onClick={handleCreate}
          disabled={isLoading}
        >
          Create Product
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const EnhancedAddItemDialog = ({
  open,
  onClose,
  onAddItem,
  onSelect,
  api,
  currentUser,
  docId,
  initialSearchTerm = ''
}) => {
  const [activeTab, setActiveTab] = useState(0); // 0 = Search, 1 = Manual Entry
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  const [manualItem, setManualItem] = useState({
    description: '',
    quantity: 1,
    unitPrice: 0,
    packSize: '',
    barcode: '',
    vendorId: '',
  });

  const [selectedSquareItem, setSelectedSquareItem] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [createProductLoading, setCreateProductLoading] = useState(false);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const handleSearch = useCallback(async () => {
    setSearchError(null);
    setIsSearching(true);
    try {
      const results = await api.searchSquareProducts(searchTerm);
      setSearchResults(results);
      if (results.length === 0) {
        setSearchError('No products found. Try another search term or create a new product.');
      }
    } catch (err) {
      console.error('Error searching products:', err);
      setSearchError('Failed to search products. Please try again.');
    } finally {
      setIsSearching(false);
    }
  }, [api, searchTerm]);

  const handleSelectSquareItem = (item) => {
    setSelectedSquareItem(item);
    setManualItem({
      ...manualItem,
      description: item.itemName,
      unitPrice: item.price,
      barcode: item.sku || '',
    });
  };

  const handleAddComplete = async () => {
    // Construct new item object
    const newItem = {
      ...manualItem,
      totalPrice: (manualItem.quantity * manualItem.unitPrice) || 0,
      squareUp: selectedSquareItem ? {
        itemId: selectedSquareItem.productId,
        itemName: selectedSquareItem.itemName,
        variationId: selectedSquareItem.variationId,
        variationName: selectedSquareItem.variationName,
        price: selectedSquareItem.price,
        barcode: selectedSquareItem.sku || '',
      } : {
        itemId: '',
        itemName: '',
        variationId: '',
        variationName: '',
        price: 0,
        barcode: '',
      }
    };

    try {
      const addedItem = await api.addItem(docId, newItem);
      onAddItem(addedItem);
      onClose();
    } catch (error) {
      console.error('Error adding item:', error);
      setSearchError('Failed to add item. Please try again.');
    }
  };

  const handleCreateNewProduct = async (productData) => {
    setCreateProductLoading(true);
    try {
      const newProduct = await api.createSquareProduct(productData);
      // Once created, select this new product and populate the fields
      handleSelectSquareItem(newProduct);
      setCreateDialogOpen(false);
    } catch (err) {
      console.error('Error creating new product:', err);
      throw err; // The dialog will handle showing error
    } finally {
      setCreateProductLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Add Invoice Item</Typography>
            <IconButton edge="end" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
            <Tab label="Search Existing Products" />
            <Tab label="Manual Entry" />
          </Tabs>
          <Divider sx={{ my: 2 }} />

          {activeTab === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box mb={2}>
                  <Typography variant="subtitle1" gutterBottom>Find a product from your Square catalog</Typography>
                  <Box display="flex" gap={1} mb={2}>
                    <TextField
                      fullWidth
                      label="Search Square Products"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                    />
                    <IconButton onClick={handleSearch} disabled={isSearching}>
                      {isSearching ? <CircularProgress size={24} /> : <SearchIcon />}
                    </IconButton>
                  </Box>
                  {searchError && <Alert severity="error" sx={{ mb: 2 }}>{searchError}</Alert>}
                  <Paper variant="outlined" sx={{ maxHeight: 400, overflow: 'auto' }}>
                    <List>
                      {searchResults.map((result) => (
                        <ListItem
                        button
                        key={result.productId}
                        selected={selectedSquareItem?.productId === result.productId}
                        onClick={() => handleSelectSquareItem(result)}
                      >
                        <ListItemText
                          primary={`${result.itemName} - ${result.variationName}`}
                          secondary={
                            <>
                              <Typography variant="body2">
                                Price: ${result.price.toFixed(2)}
                              </Typography>
                              {result.sku && (
                                <Typography variant="body2">
                                  SKU: {result.sku}
                                </Typography>
                              )}
                            </>
                          }
                        />
                      </ListItem>
                      
                      ))}
                      {searchResults.length === 0 && !isSearching && (
                        <ListItem>
                          <ListItemText primary="Search or create a new product." />
                        </ListItem>
                      )}
                    </List>
                  </Paper>
                </Box>
                <Button
                  startIcon={<AddCircleIcon />}
                  onClick={() => setCreateDialogOpen(true)}
                  color="primary"
                  variant="outlined"
                >
                  Create New Product
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>Item Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      value={manualItem.description}
                      onChange={(e) => setManualItem({ ...manualItem, description: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      type="number"
                      value={manualItem.quantity}
                      onChange={(e) => setManualItem({ ...manualItem, quantity: parseFloat(e.target.value) })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Unit Price"
                      type="number"
                      value={manualItem.unitPrice}
                      onChange={(e) => setManualItem({ ...manualItem, unitPrice: parseFloat(e.target.value) })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Pack Size"
                      value={manualItem.packSize}
                      onChange={(e) => setManualItem({ ...manualItem, packSize: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Barcode"
                      value={manualItem.barcode}
                      onChange={(e) => setManualItem({ ...manualItem, barcode: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Vendor ID"
                      value={manualItem.vendorId}
                      onChange={(e) => setManualItem({ ...manualItem, vendorId: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {activeTab === 1 && (
            <Box mt={2}>
              <Typography variant="subtitle1" gutterBottom>Manual Entry</Typography>
              <Typography variant="body2" sx={{ mb:2 }}>
                Enter item details manually. This won't create a product in Square, but will add the item to your invoice.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    value={manualItem.description}
                    onChange={(e) => setManualItem({ ...manualItem, description: e.target.value })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    type="number"
                    value={manualItem.quantity}
                    onChange={(e) => setManualItem({ ...manualItem, quantity: parseFloat(e.target.value) })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Unit Price"
                    type="number"
                    value={manualItem.unitPrice}
                    onChange={(e) => setManualItem({ ...manualItem, unitPrice: parseFloat(e.target.value) })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Pack Size"
                    value={manualItem.packSize}
                    onChange={(e) => setManualItem({ ...manualItem, packSize: e.target.value })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Barcode"
                    value={manualItem.barcode}
                    onChange={(e) => setManualItem({ ...manualItem, barcode: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Vendor ID"
                    value={manualItem.vendorId}
                    onChange={(e) => setManualItem({ ...manualItem, vendorId: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddComplete}
            startIcon={<AddIcon />}
          >
            Add Item
          </Button>
        </DialogActions>
      </Dialog>

      <CreateNewProductDialog
        open={createDialogOpen}
        onClose={(newProduct) => {
          setCreateDialogOpen(false);
          if (newProduct) {
            // If a new product was created, select it
            handleSelectSquareItem(newProduct);
            setActiveTab(0); // Switch back to search tab so user sees chosen product
          }
        }}
        onCreateProduct={handleCreateNewProduct}
        isLoading={createProductLoading}
      />
    </>
  );
};

export default React.memo(EnhancedAddItemDialog);
