import React from 'react';
import { TextField, Typography, InputAdornment, FormHelperText, Box } from '@mui/material';
import { CheckCircle, ErrorOutline } from '@mui/icons-material';
import CONSTANTS from '../constants';

const BasicInformationStep = ({ displayName, onChange }) => {
    const isValidName = displayName && displayName.length >= CONSTANTS.MIN_NAME_LENGTH;
    const helperText = isValidName
        ? 'Name looks good!'
        : `Name must be at least ${CONSTANTS.MIN_NAME_LENGTH} characters long.`;

    return (
        <Box>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Please enter your full name as it appears on your official documents.
            </Typography>
            <TextField
                fullWidth
                label="Full Name"
                name="displayName"
                value={displayName}
                onChange={onChange}
                margin="normal"
                required
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {displayName ? (
                                isValidName ? (
                                    <CheckCircle color="success" sx={{ transition: 'all 0.3s' }} />
                                ) : (
                                    <ErrorOutline color="error" sx={{ transition: 'all 0.3s' }} />
                                )
                            ) : null}
                        </InputAdornment>
                    )
                }}
                aria-describedby="name-helper-text"
                error={!isValidName && Boolean(displayName)}
            />
            <FormHelperText id="name-helper-text" sx={{ color: isValidName ? 'green' : 'error.main' }}>
                {helperText}
            </FormHelperText>
        </Box>
    );
};

export default BasicInformationStep;
