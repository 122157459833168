// ProtectedLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';

const ProtectedLayout = () => {
  return (
    <ProtectedRoute>
      <div>
        {/* Common header or navigation for protected pages */}
        <Outlet />
      </div>
    </ProtectedRoute>
  );
};

export default ProtectedLayout;