import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress, Alert } from '@mui/material';
import { getAuth, deleteUser, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

const DeleteAccountDialog = ({ open, onClose, setSuccessMessage, setError }) => {
    const auth = getAuth();
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteAccount = async () => {
        setIsLoading(true);
        try {
            const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
            await reauthenticateWithCredential(auth.currentUser, credential);
            await deleteUser(auth.currentUser);
            setSuccessMessage('Account deleted successfully.');
            onClose();
        } catch (err) {
            setError(err.message || 'Failed to delete account.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={!isLoading ? onClose : null}>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogContent>
                <Alert severity="warning" sx={{ mb: 2 }}>
                    This action cannot be undone. Please confirm your password to delete your account.
                </Alert>
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
                <Button onClick={handleDeleteAccount} variant="contained" color="error" disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : 'Delete Account'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAccountDialog;
