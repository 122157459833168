// Part 1: Imports and Setup
import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../../context/AuthContext';
import createApi from './api';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Box,
    Paper,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    InputAdornment,
    ListItemIcon,
    Avatar,
    ButtonBase,
    Alert,
    Skeleton,
    LinearProgress,
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore,
    Search,
    FilterList,
    Refresh,
} from '@mui/icons-material';

// Part 2: Loading Components
const VendorSkeleton = () => (
    <Box sx={{ my: 2 }}>
        <Skeleton variant="rectangular" height={60} sx={{ borderRadius: 1 }} />
    </Box>
);

const TableSkeleton = () => (
    <Box sx={{ width: '100%' }}>
        <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 1 }} />
    </Box>
);

// Part 3: VendorItemsTable Component
const VendorItemsTable = ({ items, loading }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('asc');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedAndFilteredItems = useMemo(() => {
        return items
            .filter((item) => {
                const searchLower = searchTerm.toLowerCase();
                return (
                    item.relatedItem?.item_data?.name?.toLowerCase().includes(searchLower) ||
                    item.item_variation_data?.name?.toLowerCase().includes(searchLower) ||
                    item.item_variation_data?.sku?.toLowerCase().includes(searchLower)
                );
            })
            .sort((a, b) => {
                const isAsc = order === 'asc';

                switch (orderBy) {
                    case 'name':
                        return isAsc
                            ? (a.relatedItem?.item_data?.name || '').localeCompare(b.relatedItem?.item_data?.name || '')
                            : (b.relatedItem?.item_data?.name || '').localeCompare(a.relatedItem?.item_data?.name || '');
                    case 'price':
                        return isAsc
                            ? (a.item_variation_data?.price_money?.amount || 0) - (b.item_variation_data?.price_money?.amount || 0)
                            : (b.item_variation_data?.price_money?.amount || 0) - (a.item_variation_data?.price_money?.amount || 0);
                    case 'sales':
                        return isAsc
                            ? (a.sales?.sevenDaysRolling || 0) - (b.sales?.sevenDaysRolling || 0)
                            : (b.sales?.sevenDaysRolling || 0) - (a.sales?.sevenDaysRolling || 0);
                    default:
                        return 0;
                }
            });
    }, [items, searchTerm, orderBy, order]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
                <TextField
                    size="small"
                    placeholder="Search items..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: 300 }}
                />
                <IconButton>
                    <FilterList />
                </IconButton>
            </Box>

            <TableContainer component={Paper} variant="outlined">
                {loading && <LinearProgress />}

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Item Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Variation</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === 'price'}
                                    direction={orderBy === 'price' ? order : 'asc'}
                                    onClick={() => handleRequestSort('price')}
                                >
                                    Price
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">Cost</TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === 'sales'}
                                    direction={orderBy === 'sales' ? order : 'asc'}
                                    onClick={() => handleRequestSort('sales')}
                                >
                                    7 Days
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">14 Days</TableCell>
                            <TableCell align="right">30 Days</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <TableSkeleton />
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortedAndFilteredItems.map((item) => (
                                <TableRow key={item.id} hover>
                                    <TableCell>{item.relatedItem?.item_data?.name}</TableCell>
                                    <TableCell>{item.item_variation_data?.name}</TableCell>
                                    <TableCell>{item.item_variation_data?.sku}</TableCell>
                                    <TableCell align="right">
                                        ${(item.item_variation_data?.price_money?.amount / 100).toFixed(2)}
                                    </TableCell>
                                    <TableCell align="right">
                                        ${(item.item_variation_data?.default_unit_cost?.amount / 100).toFixed(2)}
                                    </TableCell>

                                    {/* Sales cells with loading state */}
                                    {item.salesLoading ? (
                                        <>
                                            <TableCell align="right"><Skeleton width={30} /></TableCell>
                                            <TableCell align="right"><Skeleton width={30} /></TableCell>
                                            <TableCell align="right"><Skeleton width={30} /></TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell align="right">{item.sales?.sevenDaysRolling || 0}</TableCell>
                                            <TableCell align="right">{item.sales?.fourteenDaysRolling || 0}</TableCell>
                                            <TableCell align="right">{item.sales?.thirtyDaysRolling || 0}</TableCell>
                                        </>
                                    )}

                                    <TableCell align="center">
                                        <Box
                                            sx={{
                                                bgcolor: item.item_variation_data?.location_overrides?.some(loc => loc.sold_out)
                                                    ? 'error.main'
                                                    : 'success.main',
                                                color: 'white',
                                                px: 1,
                                                py: 0.5,
                                                borderRadius: 1,
                                                display: 'inline-block',
                                                fontSize: '0.75rem',
                                            }}
                                        >
                                            {item.item_variation_data?.location_overrides?.some(loc => loc.sold_out)
                                                ? 'Sold Out'
                                                : 'In Stock'}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
// Part 4: VendorCard Component
const VendorCard = ({ vendor, expanded, onSelect, items, loading }) => {
    const getInitials = (name) => {
        return name
            ?.split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase() || '?';
    };

    return (
        <>
            <Paper
                elevation={expanded ? 3 : 1}
                sx={{
                    my: 1,
                    transition: 'all 0.2s',
                    '&:hover': {
                        backgroundColor: 'action.hover',
                    },
                }}
            >
                <ButtonBase
                    component="div"
                    onClick={() => onSelect(vendor)}
                    sx={{ width: '100%', textAlign: 'left' }}
                >
                    <ListItem
                        sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ListItemIcon>
                            <Avatar sx={{ bgcolor: 'primary.main' }}>
                                {getInitials(vendor.name)}
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Typography variant="h6">
                                        {vendor.name || 'No Name Available'}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            bgcolor: vendor.status === 'ACTIVE' ? 'success.light' : 'warning.light',
                                            color: vendor.status === 'ACTIVE' ? 'success.dark' : 'warning.dark',
                                            px: 1,
                                            py: 0.5,
                                            borderRadius: 1,
                                            fontSize: '0.75rem',
                                        }}
                                    >
                                        {vendor.status || 'N/A'}
                                    </Box>
                                </Box>
                            }
                            secondary={
                                expanded ? (
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        {items?.length || 0} items
                                    </Typography>
                                ) : null
                            }
                        />
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </ButtonBase>
            </Paper>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 2, mb: 4, mx: 2 }}>
                    {loading ? (
                        <TableSkeleton />
                    ) : items?.length ? (
                        <VendorItemsTable items={items} loading={loading} />
                    ) : (
                        <Paper
                            variant="outlined"
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <Typography color="text.secondary">
                                No items found for this vendor
                            </Typography>
                        </Paper>
                    )}
                </Box>
            </Collapse>
        </>
    );
};

// Part 5: Main Vendors Component
const Vendors = () => {
    const { currentUser } = useAuth();
    const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser]);

    const [vendors, setVendors] = useState([]);
    const [expandedVendorId, setExpandedVendorId] = useState(null);
    const [itemsWithSales, setItemsWithSales] = useState({});
    const [loading, setLoading] = useState(false);
    const [itemLoading, setItemLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name');

    const fetchVendors = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await api.getVendors();
            setVendors(response.vendors || []);
        } catch (err) {
            console.error('Error fetching vendors:', err);
            setError('Failed to load vendors. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    // In Vendors component, replace handleSelectVendor:
    const handleSelectVendor = async (vendor) => {
        const vendorId = vendor.id;

        // If already expanded, just collapse
        if (expandedVendorId === vendorId) {
            setExpandedVendorId(null);
            return;
        }

        setExpandedVendorId(vendorId);

        // If we already have the data, don't fetch again
        if (itemsWithSales[vendorId]) {
            return;
        }

        // Set initial loading state
        setItemLoading(true);

        try {
            // First API call - fetch items (fast)
            const data = await api.fetchItemsByVendor(vendorId);
            const itemVariations = data.itemVariations || [];

            // Set initial items without sales data
            setItemsWithSales(prev => ({
                ...prev,
                [vendorId]: itemVariations.map((item) => ({
                    ...item,
                    sales: {},
                    salesLoading: true, // Add loading state for sales
                })),
            }));

            setItemLoading(false); // Remove loading state after items are loaded

            // Second API call - fetch sales history (slow)
            const itemVariationIds = itemVariations.map((item) => item.id);
            const salesHistory = await api.getSalesHistory(itemVariationIds);

            // Update items with sales data
            setItemsWithSales(prev => ({
                ...prev,
                [vendorId]: prev[vendorId].map((item) => ({
                    ...item,
                    sales: salesHistory.results[item.id] || {},
                    salesLoading: false, // Remove sales loading state
                })),
            }));

        } catch (err) {
            console.error('Error fetching vendor items:', err);
            setError(`Failed to load items for ${vendor.name}`);
            setExpandedVendorId(null);
            setItemLoading(false);
        }
    };

    useEffect(() => {
        fetchVendors();
    }, [api]);

    const filteredVendors = useMemo(() => {
        return vendors
            .filter((vendor) =>
                vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .sort((a, b) => {
                switch (sortBy) {
                    case 'name':
                        return a.name.localeCompare(b.name);
                    case 'status':
                        return a.status.localeCompare(b.status);
                    default:
                        return 0;
                }
            });
    }, [vendors, searchTerm, sortBy]);

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Box sx={{ mb: 4 }}>
                <Box sx={{
                    mb: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Typography variant="h4">
                        Vendors
                    </Typography>
                    <IconButton
                        onClick={fetchVendors}
                        disabled={loading}
                        size="small"
                    >
                        <Refresh />
                    </IconButton>
                </Box>

                {error && (
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                        action={
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={fetchVendors}
                            >
                                <Refresh />
                            </IconButton>
                        }
                    >
                        {error}
                    </Alert>
                )}

                <Box sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Search vendors..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {loading ? (
                    [...Array(3)].map((_, index) => (
                        <VendorSkeleton key={index} />
                    ))
                ) : filteredVendors.length > 0 ? (
                    <List>
                        {filteredVendors.map((vendor) => (
                            <VendorCard
                                key={vendor.id}
                                vendor={vendor}
                                expanded={expandedVendorId === vendor.id}
                                onSelect={handleSelectVendor}
                                items={itemsWithSales[vendor.id]}
                                loading={itemLoading && expandedVendorId === vendor.id}
                            />
                        ))}
                    </List>
                ) : (
                    <Paper
                        sx={{
                            p: 4,
                            textAlign: 'center',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <Typography color="text.secondary" variant="h6">
                            No vendors found
                        </Typography>
                        <Typography color="text.secondary">
                            Try adjusting your search terms
                        </Typography>
                    </Paper>
                )}
            </Box>
        </Container>
    );
};

export default Vendors;