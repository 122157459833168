import React, { useState, useEffect } from 'react';
import { Container, Typography, Snackbar, Alert, Box } from '@mui/material';
import ProductLookup from './ProductLookup';
import ProductDetails from './ProductDetails';
import PriceCalculator from './PriceCalculator';
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar'; // Adjust the import path as needed
import { useAuth } from '../Auth/useAuth'; // Adjust the import path as needed
import { useCustomerData } from '../Dashboard/Hooks/useCustomerData';





function CostCalculator() {
  const { user } = useAuth(); // Get the user from the auth context
  const { customerData } = useCustomerData(user);

  const [searchQuery, setSearchQuery] = useState('');
  const [barcode, setBarcode] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [cost, setCost] = useState('');
  const [productType, setProductType] = useState('regular');
  const [result, setResult] = useState('');
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [calculatedPrice, setCalculatedPrice] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    console.log('Product details updated:', productDetails);
  }, [productDetails]);

  const fetchProductData = async (code) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.fivespiceindiangrocery.com/api/squareproductlookup?barcode=${code}`);
      const data = await response.json();
      console.log('API response:', data);
      setProductDetails(data);
      setProductType(data.variationName?.toLowerCase().includes('vegetable') ? 'vegetable' : 'regular');
      setSnackbar({ open: true, message: 'Product found!', severity: 'success' });
      setSearchResults([]);
    } catch (error) {
      console.error('Product lookup failed:', error);
      setProductDetails(null);
      setSnackbar({ open: true, message: 'Product not found. Please check the barcode.', severity: 'error' });
    }
    setLoading(false);
  };

  const searchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.fivespiceindiangrocery.com/api/searchproducts?query=${encodeURIComponent(searchQuery)}`);
      const data = await response.json();
      console.log('Search results:', data);
      setSearchResults(data);
      if (data.length === 0) {
        setSnackbar({ open: true, message: 'No products found.', severity: 'info' });
      }
    } catch (error) {
      console.error('Product search failed:', error);
      setSnackbar({ open: true, message: 'Search failed. Please try again.', severity: 'error' });
    }
    setLoading(false);
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      searchProducts();
    }
  };

  const handleBarcodeSearch = (event) => {
    if (event.key === 'Enter') {
      fetchProductData(barcode);
    }
  };

  const selectProduct = (product) => {
    setProductDetails(product);
    setProductType(product.variationName?.toLowerCase().includes('vegetable') ? 'vegetable' : 'regular');
    setSearchResults([]);
    setSearchQuery('');
  };

  const updateProductPrice = async (newPrice, isGlobalUpdate = false) => {
    setUpdating(true);
    try {
      const response = await fetch(`https://api.fivespiceindiangrocery.com/api/updateproduct/${productDetails.variationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': user.accessToken
        },
        body: JSON.stringify({ price: newPrice, isGlobalUpdate })
      });
      const data = await response.json();
      console.log('Product updated response:', data);
      setProductDetails(prevDetails => ({
        ...prevDetails,
        price: data.price ?? prevDetails.price,
        locationSpecificPrice: isGlobalUpdate ? (data.price ?? prevDetails.locationSpecificPrice) : (data.locationSpecificPrice ?? prevDetails.locationSpecificPrice),
        defaultPrice: isGlobalUpdate ? (data.price ?? prevDetails.defaultPrice) : prevDetails.defaultPrice
      }));
      setCalculatedPrice(null);
      setSnackbar({ open: true, message: `Price updated successfully to $${newPrice.toFixed(2)}`, severity: 'success' });
    } catch (error) {
      console.error('Product update failed:', error);
      setSnackbar({ open: true, message: 'Failed to update price. Please try again.', severity: 'error' });
    }
    setUpdating(false);
  };
  
    const calculatePrice = (cost, productType) => {
      const marginRanges = productType === 'vegetable'
        ? [
          { min: 0, max: 1, margin: 0.20 },
          { min: 1.01, max: Infinity, margin: 0.15 },
        ]
        : [
          { min: 0, max: 1, margin: 0.45 },
          { min: 1.01, max: 3, margin: 0.33 },
          { min: 3.01, max: 5, margin: 0.31 },
          { min: 5.01, max: 9, margin: 0.26 },
          { min: 9.01, max: 12, margin: 0.24 },
          { min: 12.01, max: Infinity, margin: 0.20 },
        ];
  
      const { margin } = marginRanges.find(({ min, max }) => cost >= min && cost <= max);
      const markupPrice = cost / (1 - margin);
  
      return productType === 'vegetable' ? markupPrice : applyRounding(markupPrice);
    };
  
    const applyRounding = (price) => {
      const roundingTargets = [0.29, 0.49, 0.79, 0.99];
      const fraction = price % 1;
      const basePrice = Math.floor(price);
  
      if (fraction > 0 && fraction <= 0.14) {
        return basePrice - 1 + 0.99;
      }
  
      const closestTarget = roundingTargets.reduce((prev, curr) =>
        Math.abs(curr - fraction) < Math.abs(prev - fraction) ? curr : prev
      );
      const targetIndex = roundingTargets.indexOf(closestTarget);
      const nextTarget = roundingTargets[targetIndex + 1];
  
      if (nextTarget !== undefined) {
        const midpoint = (closestTarget + nextTarget) / 2;
        return fraction <= midpoint ? basePrice + closestTarget : basePrice + nextTarget;
      } else {
        return fraction <= closestTarget ? basePrice + closestTarget : basePrice + 1;
      }
    };
  
    const calculateMargin = (cost, price) => {
      return ((price - cost) / price) * 100;
    };
  
    const calculateMarkup = (cost, price) => {
      return ((price - cost) / cost) * 100;
    };
  
    const handleCalculate = () => {
      const parsedCost = parseFloat(cost);
      if (isNaN(parsedCost) || parsedCost <= 0) {
        setSnackbar({ open: true, message: 'Please enter a valid cost.', severity: 'warning' });
        return;
      }
      const price = calculatePrice(parsedCost, productType);
      const margin = calculateMargin(parsedCost, price);
      const markup = calculateMarkup(parsedCost, price);
      setCalculatedPrice(price);
      setResult(`Calculated Price: $${price.toFixed(2)}\nMargin: ${margin.toFixed(2)}%\nMarkup: ${markup.toFixed(2)}%`);
    };
    return (
      <Box>
        <ResponsiveAppBar user={customerData} />
        <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
          <Typography variant="h4" align="center" gutterBottom>Product Pricing Calculator</Typography>
  
          <ProductLookup 
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            barcode={barcode}
            setBarcode={setBarcode}
            handleSearch={handleSearch}
            handleBarcodeSearch={handleBarcodeSearch}
            searchProducts={searchProducts}
            fetchProductData={fetchProductData}
            searchResults={searchResults}
            selectProduct={selectProduct}
            loading={loading}
          />
  
          <ProductDetails 
            productDetails={productDetails}
            calculatedPrice={calculatedPrice}
            updating={updating}
            updateProductPrice={updateProductPrice}
          />
  
          <PriceCalculator 
            cost={cost}
            setCost={setCost}
            productType={productType}
            setProductType={setProductType}
            handleCalculate={handleCalculate}
          />
  
          {result && (
            <Typography variant="body1" component="pre" style={{ whiteSpace: 'pre-wrap', marginTop: '1rem' }}>
              {result}
            </Typography>
          )}
  
          <Typography variant="body2" color="textSecondary" style={{ marginTop: '2rem' }}>
            Note: For items under $1, a standard margin of 40% is applied for regular products and 20% for vegetables.
            Vegetable prices are not rounded. DO NOT USE THIS CALCULATOR FOR MILK, YOGURT, OR ANYTHING IN THE FRIDGE.
          </Typography>
  
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
            <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    );
  }
  
  export default CostCalculator;