import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ChangePasswordDialog from './ChangePasswordDialog';
import DeleteAccountDialog from './DeleteAccountDialog';

const SecuritySettings = ({ setSuccessMessage, setError }) => {
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

    return (
        <Box>
            <Typography variant="h6" color="primary" gutterBottom>
                Security
            </Typography>
            <Button
                variant="outlined"
                startIcon={<LockIcon />}
                onClick={() => setIsPasswordModalOpen(true)}
                fullWidth
                sx={{ mb: 2 }}
            >
                Change Password
            </Button>
            <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteForeverIcon />}
                onClick={() => setIsDeleteAccountModalOpen(true)}
                fullWidth
            >
                Delete Account
            </Button>

            <ChangePasswordDialog
                open={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
                setSuccessMessage={setSuccessMessage}
                setError={setError}
            />
            <DeleteAccountDialog
                open={isDeleteAccountModalOpen}
                onClose={() => setIsDeleteAccountModalOpen(false)}
                setSuccessMessage={setSuccessMessage}
                setError={setError}
            />
        </Box>
    );
};

export default SecuritySettings;
