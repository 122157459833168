const STYLES = {
    avatar: {
        width: 120,
        height: 120,
        mb: 2,
        border: '2px solid #e0e0e0',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)'
        }
    },
    container: {
        mt: 4,
        mb: 4,
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    paper: {
        p: { xs: 2, sm: 4 },
        borderRadius: 2,
        boxShadow: 3
    }
};

export default STYLES;
