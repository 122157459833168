import React, { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import ProfilePictureUpload from './ProfilePictureUpload';
import { getAuth, updateProfile } from 'firebase/auth';
import { useAuth } from '../../../context/AuthContext';

const UserInfoTab = ({ setSuccessMessage, setError }) => {
    const { currentUser } = useAuth();
    const [name, setName] = useState(currentUser?.displayName || '');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setName(currentUser?.displayName || '');
    }, [currentUser]);

    const handleSave = async () => {
        setIsSaving(true);
        const auth = getAuth();
        try {
            await updateProfile(auth.currentUser, { displayName: name });
            setSuccessMessage('Name updated successfully!');
        } catch (err) {
            setError('Failed to update name.');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <ProfilePictureUpload setSuccessMessage={setSuccessMessage} setError={setError} />
            <TextField
                label="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isSaving}
                fullWidth
            >
                {isSaving ? <CircularProgress size={24} /> : 'Save Changes'}
            </Button>
        </>
    );
};

export default UserInfoTab;
