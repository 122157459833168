export const fetchCustomerData = (token, phoneNumber) => {
    const encodedPhoneNumber = encodeURIComponent(phoneNumber).replace(/\+/g, '%2B');
    const url = `https://api.fivespiceindiangrocery.com/api/sitelookup?phonenumber=${encodedPhoneNumber}`;
    console.log(`Fetching data from URL: ${url} with token: ${encodedPhoneNumber}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `${token}`
        }
    })
    .then(response => {
        // Check if the response is okay and the content type is JSON
        if (response.ok) {
            return response.json();
        } else {
            // If the content type is not JSON, read it as text
            return response.text().then(text => {
                // Throw an error with the text message or a default error message
                throw new Error(text || `HTTP error! status: ${response.status}`);
            });
        }
    })
    .catch(error => {
        console.error('Error in fetching customer data:', error);
        throw error; // Propagate the error to be handled by the caller
    });
};

export const fetchUserTransactions = (token, userId) => {
    const url = `https://api.fivespiceindiangrocery.com/api/customerorderlookup/?id=${userId}`;
    console.log(`Fetching transactions from URL: ${url} with token: ${token}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `${token}`
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    })
    .catch(error => {
        console.error('Error in fetching user transactions:', error);
        throw error; // Propagate the error
    });
};

