export const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const validatePassword = (password) => {
    const requirements = [];
    const checks = {
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        number: /\d/.test(password),
        special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };

    if (!checks.length) requirements.push('at least 8 characters');
    if (!checks.uppercase) requirements.push('an uppercase letter');
    if (!checks.lowercase) requirements.push('a lowercase letter');
    if (!checks.number) requirements.push('a number');
    if (!checks.special) requirements.push('a special character');

    return {
        isValid: requirements.length === 0,
        requirements
    };
};

export const getErrorMessage = (error) => {
    const errorCode = error?.code || '';
    const errorMessages = {
        'auth/email-already-in-use': 'This email is already associated with an account',
        'auth/invalid-email': 'Please enter a valid email address',
        'auth/weak-password': 'Please choose a stronger password',
        'storage/unauthorized': 'Permission denied for file upload',
        'storage/canceled': 'File upload was canceled',
        'storage/unknown': 'An unknown error occurred during upload'
    };

    return errorMessages[errorCode] || error.message || 'An unexpected error occurred';
};
