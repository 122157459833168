import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useAuth } from '../../../../context/AuthContext';

const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}h ${mins}m ${secs}s`;
};

const AccountInfo = () => {
    const { currentUser } = useAuth();
    const [tokenInfo, setTokenInfo] = useState({
        token: null,
        expirationTime: null,
        issuedAtTime: null,
        signInProvider: null,
    });
    const [sessionDuration, setSessionDuration] = useState(0);

    const fetchTokenInfo = async (logChanges = false) => {
        if (currentUser) {
            try {
                const idTokenResult = await currentUser.getIdTokenResult();
                
                if (logChanges) {
                    console.log('Token Info Changes:');
                    console.log('Old Token:', tokenInfo.token);
                    console.log('New Token:', idTokenResult.token);
                    console.log('Old Expiration Time:', tokenInfo.expirationTime);
                    console.log('New Expiration Time:', idTokenResult.expirationTime);
                    console.log('Old Issued At Time:', tokenInfo.issuedAtTime);
                    console.log('New Issued At Time:', idTokenResult.issuedAtTime);
                }

                setTokenInfo({
                    token: idTokenResult.token,
                    expirationTime: idTokenResult.expirationTime,
                    issuedAtTime: idTokenResult.issuedAtTime,
                    signInProvider: idTokenResult.signInProvider || 'N/A',
                });
            } catch (error) {
                console.error('Error fetching token info:', error);
            }
        }
    };

    const handleTokenRefresh = async () => {
        try {
            console.log('Refreshing token...');
            await currentUser.getIdToken(true); // Force token refresh
            console.log('Token refreshed successfully');
            fetchTokenInfo(true); // Fetch new token info with logging
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };

    useEffect(() => {
        fetchTokenInfo();
    }, [currentUser]);

    useEffect(() => {
        if (tokenInfo.issuedAtTime) {
            const issuedAt = new Date(tokenInfo.issuedAtTime).getTime();
            const interval = setInterval(() => {
                const now = Date.now();
                setSessionDuration(Math.floor((now - issuedAt) / 1000)); // Duration in seconds
            }, 1000);

            return () => clearInterval(interval); // Cleanup interval
        }
    }, [tokenInfo.issuedAtTime]);

    return (
        <Box>
            <Typography variant="h6" color="primary" gutterBottom>
                Account Information
            </Typography>
            <Typography>Name: {currentUser?.displayName || 'Not set'}</Typography>
            <Typography>Email: {currentUser?.email || 'Not set'}</Typography>
            <Typography>Last Login: {currentUser?.metadata.lastSignInTime || 'N/A'}</Typography>
            <Typography>Account Created: {currentUser?.metadata.creationTime || 'N/A'}</Typography>
            <Typography>Sign-in Provider: {tokenInfo.signInProvider}</Typography>
            <Typography>Token Expiration Time: {tokenInfo.expirationTime || 'N/A'}</Typography>
            <Typography>
                Session Duration: {sessionDuration ? formatDuration(sessionDuration) : 'Calculating...'}
            </Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={handleTokenRefresh}
                sx={{ mt: 2 }}
            >
                Refresh Token
            </Button>
        </Box>
    );
};

export default AccountInfo;
