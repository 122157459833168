import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create a context for the authentication state
export const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// Auth provider component
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();

        // Listener for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            setLoading(false);

            if (user) {
                try {
                    const token = await user.getIdToken(true);
                    console.log('Token refreshed on auth state change:', token);
                } catch (error) {
                    console.error('Error refreshing token:', error);
                }
            }
        });

        return unsubscribe;
    }, []);

    // Periodically refresh the token
    useEffect(() => {
        const intervalId = setInterval(async () => {
            const user = getAuth().currentUser;
            if (user) {
                try {
                    await user.getIdToken(true);
                    console.log('Token refreshed periodically');
                } catch (error) {
                    console.error('Error refreshing token periodically:', error);
                }
            }
        }, 55 * 60 * 1000); // Refresh token every 55 minutes

        return () => clearInterval(intervalId);
    }, []);

    const manualTokenRefresh = async () => {
        const user = getAuth().currentUser;
        if (user) {
            try {
                await user.getIdToken(true);
                console.log('Token manually refreshed');
            } catch (error) {
                console.error('Error manually refreshing token:', error);
            }
        }
    };

    const value = {
        currentUser,
        loading,
        refreshUserToken: manualTokenRefresh,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
