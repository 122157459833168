import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import GeneralSettings from './GeneralSettings';
import Integrations from './Integrations';
import TeamManagement from './TeamManagement';
import CompanyInfo from './CompanyInfo';

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const Settings = () => {
  const { currentUser } = useOutletContext();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (!currentUser) {
      setError('No authenticated user found');
      setLoading(false);
      return;
    }
    setLoading(false); // Simulate data loading
  }, [currentUser]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper elevation={3}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
            <Tab label="General Settings" />
            <Tab label="Integrations" />
            <Tab label="Team Management" />
            <Tab label="Company Info" />
          </Tabs>
        </Box>

        <Snackbar
          open={!!error || !!success}
          autoHideDuration={6000}
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
        >
          <Alert severity={error ? 'error' : 'success'}>{error || success}</Alert>
        </Snackbar>

        <TabPanel value={activeTab} index={0}>
          <GeneralSettings onError={setError} onSuccess={setSuccess} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Integrations currentUser={currentUser} onError={setError} onSuccess={setSuccess} />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <TeamManagement currentUser={currentUser} onError={setError} onSuccess={setSuccess} />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <CompanyInfo currentUser={currentUser} onError={setError} onSuccess={setSuccess} />
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default Settings;
