import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Paper,
  InputAdornment,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Restore as RestoreIcon,
  Sort as SortIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

const DEFAULT_MARGIN_RANGES = [
  { min: 0, max: 0.33, margin: 65 },
  { min: 0.34, max: 0.49, margin: 60 },
  { min: 0.50, max: 0.74, margin: 55 },
  { min: 0.75, max: 0.99, margin: 50 },
  { min: 1.00, max: 1.99, margin: 45 },
  { min: 2.00, max: 2.99, margin: 40 },
  { min: 3.00, max: 3.99, margin: 35 },
  { min: 4.00, max: 4.99, margin: 33 },
  { min: 5.00, max: 7.49, margin: 30 },
  { min: 7.50, max: 9.99, margin: 28 },
  { min: 10.00, max: 12.49, margin: 26 },
  { min: 12.50, max: 14.99, margin: 25 },
  { min: 15.00, max: 19.99, margin: 23 },
  { min: 20.00, max: 24.99, margin: 22 },
  { min: 25.00, max: 49.99, margin: 20 },
  { min: 50.00, max: 99.99, margin: 18 },
  { min: 100.00, max: Infinity, margin: 15 }
];

const UpdateMarginRanges = ({
  open,
  onClose,
  category,
  initialMarginRanges,
  onSave,
  isLoading
}) => {
  const [marginRanges, setMarginRanges] = useState([]);
  const [error, setError] = useState(null);
  const [warnings, setWarnings] = useState([]);

  useEffect(() => {
    setMarginRanges(initialMarginRanges || DEFAULT_MARGIN_RANGES);
  }, [initialMarginRanges]);

  const handleRangeChange = useCallback((index, field, value) => {
    setMarginRanges(prevRanges => {
      const newRanges = [...prevRanges];
      let parsedValue = value;

      if (field === 'margin') {
        parsedValue = parseFloat(value);
      } else if (field === 'min' || field === 'max') {
        if (field === 'max' && value === 'Infinity') {
          parsedValue = Infinity;
        } else {
          parsedValue = value === '' ? '' : parseFloat(value);
        }
      }

      newRanges[index][field] = parsedValue;
      return newRanges;
    });
  }, []);

  const addNewRange = useCallback(() => {
    setMarginRanges(prev => {
      const lastMax = prev[prev.length - 1].max;
      const newMin = (lastMax && lastMax !== Infinity) ? lastMax : (prev[prev.length - 1].min + 1);
      return [...prev, { min: newMin || 0, max: Infinity, margin: 20 }];
    });
  }, []);

  const removeRange = useCallback((index) => {
    setMarginRanges(prev => {
      if (prev.length > 1) {
        const newRanges = prev.filter((_, i) => i !== index);
        return newRanges;
      }
      return prev;
    });
  }, []);

  const restoreDefaults = useCallback(() => {
    setMarginRanges(DEFAULT_MARGIN_RANGES);
    setError(null);
    setWarnings([]);
  }, []);

  const sortRanges = useCallback(() => {
    setMarginRanges(prev =>
      [...prev].sort((a, b) => (a.min || 0) - (b.min || 0))
    );
  }, []);

  const validateRanges = () => {
    const errors = [];
    const warnings = [];
    const rangesCopy = [...marginRanges];

    // Sort by min to check for overlaps/gaps
    rangesCopy.sort((a, b) => (a.min || 0) - (b.min || 0));

    for (let i = 0; i < rangesCopy.length; i++) {
      const { min, max, margin } = rangesCopy[i];
      const rangeName = `Range ${i + 1}`;

      if (min < 0 || (max !== Infinity && max < 0)) {
        errors.push(`${rangeName}: Costs cannot be negative.`);
      }

      if (margin < 0 || margin > 100 || isNaN(margin)) {
        errors.push(`${rangeName}: Margin must be between 0 and 100%.`);
      }

      if (max !== Infinity && min >= max) {
        errors.push(`${rangeName}: Min cost must be less than max cost.`);
      }

      // Check overlaps
      if (i > 0 && rangesCopy[i - 1].max !== Infinity && rangesCopy[i - 1].max > min) {
        errors.push(`${rangeName}: Overlaps with previous range.`);
      }

      // Check gaps (warn if there's a gap > $0.01)
      if (i > 0 && rangesCopy[i - 1].max !== Infinity && min - rangesCopy[i - 1].max > 0.01) {
        warnings.push(`Gap detected between ${i} and ${i + 1} (${rangesCopy[i - 1].max} to ${min})`);
      }
    }

    setError(errors.length > 0 ? errors.join('\n') : null);
    setWarnings(warnings);

    return errors.length === 0;
  };

  const handleSave = async () => {
    if (validateRanges()) {
      // Sort before saving to ensure no overlap
      const sortedRanges = [...marginRanges].sort((a, b) => (a.min || 0) - (b.min || 0));
      try {
        await onSave(sortedRanges);
      } catch (error) {
        setError('Failed to save margin ranges.');
      }
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Update Margin Ranges for {category?.name}</Typography>
        <Typography variant="caption">Set margin percentages based on cost ranges</Typography>
      </DialogTitle>

      <DialogContent>
        {(error || warnings.length > 0) && (
          <Box mb={3}>
            {error && <Alert severity="error">{error}</Alert>}
            {warnings.length > 0 && <Alert severity="warning">{warnings.map((w, i) => <div key={i}>{w}</div>)}</Alert>}
          </Box>
        )}

        <Grid container spacing={2} mb={1}>
          <Grid item xs={4}><Typography variant="subtitle2">Min Cost ($)</Typography></Grid>
          <Grid item xs={4}><Typography variant="subtitle2">Max Cost ($ or Infinity)</Typography></Grid>
          <Grid item xs={2}><Typography variant="subtitle2">Margin (%)</Typography></Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        <Box sx={{ maxHeight: '50vh', overflow: 'auto', mb:2 }}>
          {marginRanges.map((range, index) => {
            const { min, max, margin } = range;
            const minError = min < 0 || (max !== Infinity && min >= max);
            const maxError = max !== Infinity && (max <= min || max < 0);
            const marginError = margin < 0 || margin > 100 || isNaN(margin);

            return (
              <Paper key={index} sx={{ p: 2, mb: 2, border: 1, borderColor: '#ddd' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      label="Min Cost"
                      type="number"
                      value={min === undefined ? '' : min}
                      onChange={(e) => handleRangeChange(index, 'min', e.target.value)}
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      size="small"
                      fullWidth
                      error={minError}
                      helperText={minError ? "Check min/max values" : ""}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <TextField
                        label="Max Cost"
                        type="number"
                        value={max === Infinity ? '' : (max === undefined ? '' : max)}
                        onChange={(e) => handleRangeChange(index, 'max', e.target.value === '' ? Infinity : e.target.value)}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        size="small"
                        fullWidth
                        error={maxError}
                        helperText={maxError ? "Check min/max values" : ""}
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={max === Infinity}
                          onChange={(e) => handleRangeChange(index, 'max', e.target.checked ? 'Infinity' : (min+1))}
                        />}
                        label="No Limit"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Margin"
                      type="number"
                      value={margin === undefined ? '' : margin}
                      onChange={(e) => handleRangeChange(index, 'margin', e.target.value)}
                      InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                      size="small"
                      fullWidth
                      error={marginError}
                      helperText={marginError ? "0-100%" : ""}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Remove Range">
                      <IconButton onClick={() => removeRange(index)} color="error" size="small" disabled={marginRanges.length === 1}>
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
        </Box>

        <Box display="flex" justifyContent="space-between" mt={1}>
          <Button onClick={restoreDefaults} startIcon={<RestoreIcon />}>Reset to Defaults</Button>
          <Box>
            <Button onClick={sortRanges} startIcon={<SortIcon />} sx={{ mr: 1 }}>Sort</Button>
            <Button onClick={addNewRange} startIcon={<AddIcon />}>Add Range</Button>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button onClick={handleSave} disabled={isLoading} startIcon={<SaveIcon />}>
          {isLoading ? <CircularProgress size={20} /> : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMarginRanges;
