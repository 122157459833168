import React, { memo } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const FILTER_OPTIONS = [
  { value: 'all', label: 'All Invoices' },
  { value: 'new', label: 'New' },
  { value: 'pending', label: 'Pending' },
  { value: 'done', label: 'Done' },
  { value: 'reported', label: 'Reported' }
];

const SearchFilterSection = memo(({
  searchQuery = '',
  filterStatus = 'all',
  onSearchChange,
  onFilterChange
}) => {
  console.log('SearchFilterSection Render:', {
    searchQuery,
    filterStatus,
    hasSearchChange: !!onSearchChange,
    hasFilterChange: !!onFilterChange
  });

  const handleSearchChange = (e) => {
    console.log('Search Change:', {
      currentValue: searchQuery,
      newValue: e.target.value,
      event: e
    });
    onSearchChange?.(e);
  };

  const handleFilterChange = (e) => {
    console.log('Filter Change:', {
      currentValue: filterStatus,
      newValue: e.target.value,
      event: e
    });
    onFilterChange?.(e);
  };

  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      mb: 2,
      flexDirection: { xs: 'column', sm: 'row' }
    }}>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        placeholder="Search by vendor, date, or invoice number"
        value={searchQuery || ''}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'action.active' }} />
            </InputAdornment>
          ),
        }}
        sx={{
          flex: { sm: 1 },
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'background.paper'
          }
        }}
      />

      <FormControl
        variant="outlined"
        size="small"
        sx={{
          minWidth: { xs: '100%', sm: 200 },
          backgroundColor: 'background.paper'
        }}
      >
        <InputLabel>Status</InputLabel>
        <Select
          value={filterStatus || 'all'}
          onChange={handleFilterChange}
          label="Status"
        >
          {FILTER_OPTIONS.map(({ value, label }) => {
            console.log('Rendering option:', { value, label });
            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
});

// Add display name for debugging
SearchFilterSection.displayName = 'SearchFilterSection';

export default SearchFilterSection;