// src/components/InvoiceMainPage/InvoiceUploadDialog.jsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const InvoiceUploadDialog = ({ open, onClose, onDrop, isLoading }) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Upload Invoice</DialogTitle>
            <DialogContent>
                <Box {...getRootProps()} sx={{ border: '2px dashed grey', p: 2, textAlign: 'center', cursor: 'pointer' }}>
                    <input {...getInputProps()} />
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <Typography variant="h6" color="textSecondary">
                            Drag & drop a PDF file here, or click to select one
                        </Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceUploadDialog;
