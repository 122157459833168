import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import CONSTANTS from '../constants';

const StepperProgress = ({ activeStep }) => (
    <Stepper activeStep={activeStep}>
        {CONSTANTS.STEPS.map((label) => (
            <Step key={label}>
                <StepLabel>{label}</StepLabel>
            </Step>
        ))}
    </Stepper>
);

export default StepperProgress;
