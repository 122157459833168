import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';

const firestore = getFirestore();

const TeamManagement = ({ currentUser, onError, onSuccess }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    const loadTeamMembers = async () => {
      setLoading(true);
      try {
        const companyId = 'mock-company-id'; // Replace with actual companyId
        const membersQuery = query(collection(firestore, 'users'), where('companyId', '==', companyId));
        const snapshot = await getDocs(membersQuery);
        const members = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTeamMembers(members);
      } catch (error) {
        onError(`Error loading team members: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    loadTeamMembers();
  }, [onError]);

  const handleInvite = async () => {
    if (!inviteEmail) {
      onError('Please enter a valid email');
      return;
    }

    setInviteDialogOpen(false);
    try {
      await addDoc(collection(firestore, 'invitations'), {
        email: inviteEmail,
        companyId: 'mock-company-id', // Replace with actual companyId
        invitedBy: currentUser.uid,
        status: 'pending',
        createdAt: new Date(),
      });
      onSuccess('Invitation sent successfully');
      setInviteEmail('');
    } catch (error) {
      onError(`Failed to send invitation: ${error.message}`);
    }
  };

  const handleRemoveMember = async (userId) => {
    setRemoving(true);
    try {
      await updateDoc(doc(firestore, 'users', userId), {
        companyId: null,
        isAdmin: false,
      });
      setTeamMembers((prev) => prev.filter((member) => member.id !== userId));
      onSuccess('Member removed successfully');
    } catch (error) {
      onError(`Failed to remove member: ${error.message}`);
    } finally {
      setRemoving(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Team Management
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {teamMembers.map((member) => (
            <ListItem key={member.id}>
              <ListItemText primary={member.email || 'Unknown'} secondary={member.isAdmin ? 'Admin' : 'Member'} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleRemoveMember(member.id)} disabled={removing}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setInviteDialogOpen(true)}
        sx={{ mt: 2 }}
      >
        Invite Member
      </Button>

      <Dialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)}>
        <DialogTitle>Invite Team Member</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleInvite} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TeamManagement;
