import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCustomerData } from '../API/apiService';
import { useCustomerExistence } from '../../../context/CustomerExistenceContext';
import { useAuth } from '../../Auth/useAuth';

export const useCustomerData = (currentUser) => {
    const {logout} = useAuth();
    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setDoesCustomerExist, setIsCustomerDataLoaded } = useCustomerExistence();

    useEffect(() => {
        if (!currentUser) {
            console.log('No current user, skipping fetch');
            setIsLoading(false);
            return;
        }
        
        currentUser.getIdToken()
            .then(token => {
                console.log(currentUser)
                console.log('Token retrieved:', token);
                return fetchCustomerData(token, currentUser.phoneNumber);
            })
            .then(data => {
                console.log('Data fetched:', data);
                setCustomerData(data);
                setDoesCustomerExist(true); // Customer exists
                setIsCustomerDataLoaded(true); // Indicate that customer data is fully loaded
            })
            .catch(error => {
                console.error('Error in useCustomerData:', error);
                setError(error.message);

                // Check if the error message indicates that the customer does not exist
                if (error.message === 'Customer does not exist') {
                    logout();
                    navigate('/'); // Redirect to the account creation page
                    setDoesCustomerExist(false);
                }
            })
            .finally(() => setIsLoading(false));
    }, [currentUser, navigate, setDoesCustomerExist]);

    return { customerData, isLoading, error };
};
