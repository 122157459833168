import React, { useState, useEffect } from 'react';
import { TextField, Typography, InputAdornment, IconButton, Button, Alert } from '@mui/material';
import { CheckCircle, ErrorOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { getAuth, EmailAuthProvider, linkWithCredential } from 'firebase/auth';
import { validateEmail, validatePassword } from '../utils';

const EmailSetupStep = ({ email, password, showPassword, onChange, onTogglePassword, onNextStep }) => {
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailLinked, setIsEmailLinked] = useState(false);

    useEffect(() => {
        const checkEmailLinked = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (currentUser) {
                const hasPasswordProvider = currentUser.providerData.some(
                    (provider) => provider.providerId === 'password'
                );
                setIsEmailLinked(hasPasswordProvider);
            }
        };

        checkEmailLinked();
    }, []);

    const handleEmailPasswordSetup = async () => {
        setError('');
        setSuccessMessage('');

        if (!validateEmail(email)) {
            setError('Please enter a valid email.');
            return;
        }

        const passwordValidation = validatePassword(password);
        if (!passwordValidation.isValid) {
            setError(`Password must contain: ${passwordValidation.requirements.join(', ')}`);
            return;
        }

        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (!currentUser) {
            setError('No authenticated user found.');
            return;
        }

        try {
            setIsLoading(true);

            // Create a credential with email and password
            const credential = EmailAuthProvider.credential(email, password);

            // Link email/password to the authenticated user
            await linkWithCredential(currentUser, credential);

            setSuccessMessage('Email and password setup successfully!');
            setIsEmailLinked(true);

            // Proceed to next step after successful linking
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setError('This email is already in use by another account.');
            } else {
                console.log(error)
                setError('Failed to link email and password. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleProceed = () => {
        if (isEmailLinked) {
            onNextStep();
        } else {
            setError('Please complete email and password setup before proceeding.');
        }
    };

    return (
        <div>

            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            {successMessage && <Alert severity="success" sx={{ mb: 2, mt: 2 }}>{successMessage}</Alert>}

            <TextField
                fullWidth
                label="Email"
                name="email"
                value={email}
                onChange={onChange}
                margin="normal"
                required
                InputProps={{
                    endAdornment: email && (
                        <InputAdornment position="end">
                            {validateEmail(email) ? <CheckCircle color="success" /> : <ErrorOutline color="error" />}
                        </InputAdornment>
                    )
                }}
            />
            <TextField
                fullWidth
                label="Password"
                name="password"
                value={password}
                onChange={onChange}
                type={showPassword ? 'text' : 'password'}
                margin="normal"
                required
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onTogglePassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {!isEmailLinked && (
                <Button
                    variant="contained"
                    onClick={handleEmailPasswordSetup}
                    disabled={isLoading}
                    sx={{ mt: 2 }}
                >
                    {isLoading ? 'Setting up...' : 'Set Email and Password'}
                </Button>
            )}
        </div>
    );
};

export default EmailSetupStep;
