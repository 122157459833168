import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, CircularProgress, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const firestore = getFirestore();

const CompanyInfo = ({ currentUser, onError, onSuccess }) => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState({
    companyName: '',
    address: '',
  });

  useEffect(() => {
    const loadCompany = async () => {
      try {
        const companyId = 'mock-company-id'; // Replace with actual companyId
        const docRef = doc(firestore, 'companies', companyId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setCompany(data);
          setForm({
            companyName: data.companyName || '',
            address: data.address || '',
          });
        }
      } catch (error) {
        onError(`Error loading company info: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    loadCompany();
  }, [onError]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (!company) return;
    setSaving(true);
    try {
      const companyId = 'mock-company-id'; // Replace with actual companyId
      await updateDoc(doc(firestore, 'companies', companyId), {
        ...form,
        updatedAt: new Date(),
      });
      onSuccess('Company details updated successfully');
    } catch (error) {
      onError(`Error saving company info: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Company Information
      </Typography>
      <TextField
        label="Company Name"
        name="companyName"
        value={form.companyName}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Address"
        name="address"
        value={form.address}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={saving}
        startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default CompanyInfo;
