import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Container, 
    Typography, 
    List, 
    ListItem, 
    ListItemText, 
    Button, 
    Box, 
    Paper 
} from '@mui/material';

const updates = [
    'E-commerce Platform Launch - Shop your favorite spices online!',
    'Improved Loyalty App Experience - Easier and more rewarding!',
    'Delivery Options - Get your groceries delivered to your doorstep.',
    'Buy Online, Pick Up In Store - Convenience at its best.',
    'Cool Discounts - Save more with our exclusive offers.',
    'Weekly Sales - Don\'t miss out on our special deals every week.',
    'And many more exciting features coming your way!'
];

const SiteUpdates = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                <Typography 
                    variant="h4" 
                    component="h1" 
                    gutterBottom 
                    textAlign="center" 
                    sx={{ fontWeight: 'bold' }}
                >
                    What's Coming Up at Five Spice Indian Grocery
                </Typography>
                <List>
                    {updates.map((update, index) => (
                        <ListItem key={index}>
                            <ListItemText 
                                primary={update} 
                                primaryTypographyProps={{ variant: 'body1', fontWeight: 500 }} 
                            />
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        size="large" 
                        onClick={() => navigate('/')}
                        sx={{ textTransform: 'none', fontWeight: 'bold' }}
                    >
                        Go Back to Homepage
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default SiteUpdates;
