import React from 'react';
import { Box, Button, Avatar, Typography, LinearProgress } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

const ProfilePictureStep = ({ profilePicture, isLoading, uploadProgress, onChange }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                gap: 2,
                mt: 3,
                p: 3,
                backgroundColor: '#fff',
                borderRadius: 2,
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
                maxWidth: 400,
                mx: 'auto',
            }}
        >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Personalize Your Profile
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Add a profile picture to make your account stand out.
            </Typography>

            <Box sx={{ position: 'relative', display: 'inline-block', mt: 2 }}>
                <Avatar
                    src={profilePicture || ''}
                    sx={{
                        width: 120,
                        height: 120,
                        border: '2px solid #e0e0e0',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'scale(1.03)',
                        },
                        opacity: isLoading ? 0.7 : 1,
                    }}
                    alt="Profile Picture"
                />
            </Box>

            {isLoading && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        sx={{
                            height: 6,
                            borderRadius: 3,
                        }}
                    />
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                        Uploading: {Math.round(uploadProgress)}%
                    </Typography>
                </Box>
            )}

            <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
                disabled={isLoading}
                sx={{
                    mt: 2,
                    backgroundColor: '#007bff',
                    color: '#fff',
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    width: '100%',
                    maxWidth: 200,
                    '&:hover': {
                        backgroundColor: '#0056b3',
                    },
                }}
            >
                {profilePicture ? 'Change Photo' : 'Upload Photo'}
                <input type="file" hidden accept="image/*" onChange={onChange} />
            </Button>
        </Box>
    );
};

export default ProfilePictureStep;
