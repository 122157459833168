import { Grid, TextField } from "@mui/material";

const InvoiceDetailsForm = ({
  vendorName,
  setVendorName,
  invoiceDate,
  setInvoiceDate,
  invoiceNumber,
  setInvoiceNumber,
  isInvoiceDone,
  handleFieldChange,
}) => (
  <Grid container spacing={2} mb={2}>
    <Grid item xs={12} sm={4}>
      <TextField
        fullWidth
        label="Vendor Name"
        value={vendorName}
        onChange={handleFieldChange(setVendorName)}
        disabled={isInvoiceDone}
        variant="outlined"
        size="small"
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        fullWidth
        label="Invoice Date"
        type="date"
        value={invoiceDate}
        onChange={handleFieldChange(setInvoiceDate)}
        InputLabelProps={{ shrink: true }}
        inputProps={{ max: new Date().toISOString().split("T")[0] }}
        disabled={isInvoiceDone}
        variant="outlined"
        size="small"
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        fullWidth
        label="Invoice Number"
        value={invoiceNumber}
        onChange={handleFieldChange(setInvoiceNumber)}
        disabled={isInvoiceDone}
        variant="outlined"
        size="small"
      />
    </Grid>
  </Grid>
);

export default InvoiceDetailsForm;
