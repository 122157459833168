import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Button, List, ListItem, Typography, Divider, Grid, Box
} from '@mui/material';

const SubmitOverviewDialog = ({ open, onClose, items, invoiceTotal, onConfirm }) => {
    const calculatedTotal = items.reduce((acc, item) => acc + (item.unitPrice * item.quantity), 0);
    const grossTotalSales = items.reduce((acc, item) => acc + ((item.squareUp.price / 100) * (item.quantity * (item.packSize || 1))), 0);
    const margin = ((grossTotalSales - calculatedTotal) / grossTotalSales) * 100;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Review and Submit Invoice</DialogTitle>
            <DialogContent>
                <List>
                    {items.map((item, index) => {
                        const costPerUnit = item.unitPrice / (item.packSize || 1);
                        const unitsReceived = item.quantity * (item.packSize || 1);
                        const totalCost = item.unitPrice * item.quantity;
                        const itemGrossSales = (item.squareUp.price / 100) * unitsReceived;
                        const itemMargin = ((itemGrossSales - totalCost) / itemGrossSales) * 100;
                        return (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography variant="h6">{item.squareUp.itemName}</Typography>
                                            <Typography variant="subtitle1" color="textSecondary">{item.squareUp.variationName}</Typography>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right">
                                            <Typography variant="body2" color="textPrimary">
                                                Units Received: {unitsReceived}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="textPrimary">
                                                Cost per Unit: ${costPerUnit.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right">
                                            <Typography variant="body2" color="textPrimary">
                                                Price from Square: ${(item.squareUp.price / 100).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} textAlign="right">
                                            <Typography variant="body2" color="textPrimary">
                                                Total Cost: ${totalCost.toFixed(2)}
                                            </Typography>
                                            <Typography variant="body2" color="textPrimary">
                                                Gross Sales: ${itemGrossSales.toFixed(2)}
                                            </Typography>
                                            <Typography variant="body2" color="textPrimary">
                                                Item Margin: {itemMargin.toFixed(2)}%
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Box flexGrow={1}>
                    <Typography variant="body2" color="textPrimary">
                        Calculated Total: ${calculatedTotal.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                        Invoice Total: ${invoiceTotal.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                        Gross Total Sales: ${grossTotalSales.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                        Margin: {margin.toFixed(2)}%
                    </Typography>
                </Box>
                <Button onClick={onClose} color="primary">Cancel</Button>
                <Button onClick={onConfirm} variant="contained" color="primary">Submit to Square</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(SubmitOverviewDialog);
