import React, { useState, useEffect } from 'react';
import { Container, Box, Button, Paper, Typography, Alert, LinearProgress } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../../../context/AuthContext';
import CONSTANTS from './constants';
import STYLES from './styles';
import StepperProgress from './components/StepperProgress';
import BasicInformationStep from './components/BasicInformationStep';
import EmailSetupStep from './components/EmailSetupStep';
import ProfilePictureStep from './components/ProfilePictureStep';
import { validateEmail, validatePassword, getErrorMessage } from './utils';
import { getAuth, updateProfile, EmailAuthProvider, linkWithCredential } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const UserKYC = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const { currentUser } = useAuth();
    const [activeStep, setActiveStep] = useState(0);
    const [profileData, setProfileData] = useState({
        displayName: '',
        email: '',
        password: '',
        profilePicture: null,
        phoneNumber: '',
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (currentUser) {
            setProfileData({
                displayName: currentUser.displayName || '',
                email: currentUser.email || '',
                password: '',
                profilePicture: currentUser.photoURL || null,
                phoneNumber: currentUser.phoneNumber || ''
            });
        }
    }, [currentUser]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prev) => ({ ...prev, [name]: value }));
        setError('');
    };

    const validateStep = () => {
        setError('');
        switch (activeStep) {
            case 0:
                if (!profileData.displayName.trim()) {
                    setError('Full Name is required.');
                    return false;
                }
                if (profileData.displayName.length < CONSTANTS.MIN_NAME_LENGTH) {
                    setError(`Name must be at least ${CONSTANTS.MIN_NAME_LENGTH} characters.`);
                    return false;
                }
                break;
            case 1:
                if (!validateEmail(profileData.email)) {
                    setError('Please enter a valid email address.');
                    return false;
                }
                const passwordValidation = validatePassword(profileData.password);
                if (!passwordValidation.isValid) {
                    setError(`Password must contain: ${passwordValidation.requirements.join(', ')}`);
                    return false;
                }
                break;
            default:
                break;
        }
        return true;
    };

    const handleNext = async () => {
        if (!validateStep()) return;

        if (activeStep === steps.length - 1) {
            await handleProfileUpdate();
            navigate('/dashboard'); // Navigate to dashboard after completion
        } else {
            setActiveStep((prev) => prev + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
        setError('');
    };

    const handlePhotoUpload = async (event) => {
        const file = event.target.files?.[0];
        if (!file) {
            setError('No file selected.');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            setError('File size exceeds 5MB.');
            return;
        }

        if (!file.type.startsWith('image/')) {
            setError('Invalid file type. Please select an image.');
            return;
        }

        try {
            setIsLoading(true);
            setUploadProgress(0);
            const storage = getStorage();
            const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    setError(getErrorMessage(error));
                    setIsLoading(false);
                },
                async () => {
                    const downloadURL = await getDownloadURL(storageRef);
                    setProfileData((prev) => ({ ...prev, profilePicture: downloadURL }));
                    setSuccessMessage('Profile picture uploaded successfully!');
                    setTimeout(() => setSuccessMessage(''), CONSTANTS.SUCCESS_MESSAGE_DURATION);
                    setIsLoading(false);
                }
            );
        } catch (error) {
            setError(getErrorMessage(error));
            setIsLoading(false);
        }
    };

    const handleProfileUpdate = async () => {
        try {
            setIsLoading(true);
            const auth = getAuth();
            const { displayName, profilePicture, email, password } = profileData;

            await updateProfile(auth.currentUser, { displayName, photoURL: profilePicture });

            if (!auth.currentUser.providerData.some((p) => p.providerId === 'password')) {
                const credential = EmailAuthProvider.credential(email, password);
                await linkWithCredential(auth.currentUser, credential);
            }

            setSuccessMessage('Profile updated successfully!');
        } catch (error) {
            setError(getErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const steps = [
        {
            label: 'Basic Information',
            component: (
                <BasicInformationStep
                    displayName={profileData.displayName}
                    onChange={handleInputChange}
                />
            )
        },
        {
            label: 'Email Setup',
            component: (
                <EmailSetupStep
                    email={profileData.email}
                    password={profileData.password}
                    onChange={handleInputChange}
                />
            )
        },
        {
            label: 'Profile Picture',
            component: (
                <ProfilePictureStep
                    profilePicture={profileData.profilePicture}
                    onChange={handlePhotoUpload}
                    uploadProgress={uploadProgress}
                />
            )
        },
        {
            label: 'Summary',
            component: (
                <Box>
                    <Typography variant="h6">Summary</Typography>
                    <Typography>Name: {profileData.displayName}</Typography>
                    <Typography>Email: {profileData.email}</Typography>
                    <Typography>Phone Number: {profileData.phoneNumber}</Typography>
                </Box>
            )
        }
    ];

    return (
        <Container component="main" maxWidth="sm" sx={STYLES.container}>
            <Paper sx={STYLES.paper}>
                <Typography variant="h4" align="center">Complete Your Profile</Typography>
                <StepperProgress activeStep={activeStep} steps={steps} />
                {error && <Alert severity="error">{error}</Alert>}
                {successMessage && <Alert severity="success">{successMessage}</Alert>}

                <Box>
                    {steps[activeStep]?.component}
                </Box>

                {isLoading && <LinearProgress sx={{ mt: 2 }} />}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    {activeStep > 0 && <Button startIcon={<ArrowBack />} onClick={handleBack}>Back</Button>}
                    <Button
                        variant="contained"
                        endIcon={<ArrowForward />}
                        onClick={handleNext}
                        disabled={isLoading}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default UserKYC;
