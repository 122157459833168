import React, { useState } from 'react';
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material';
import UserInfoTab from './UserInfoTab';
import UserContactTab from './UserContactTab';
import UserSettingsTab from './UserSettings/UserSettingsTab';
import NotificationSnackbar from './NotificationSnackbar';

const UserProfile = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState(null);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
            <Typography variant="h5" gutterBottom>
                User Profile
            </Typography>
            <Paper sx={{ p: 3 }}>
                <Tabs value={currentTab} onChange={handleTabChange} centered>
                    <Tab label="User Info" />
                    <Tab label="Contact Info" />
                    <Tab label="Settings" />
                </Tabs>

                {currentTab === 0 && <UserInfoTab setSuccessMessage={setSuccessMessage} setError={setError} />}
                {currentTab === 1 && <UserContactTab setSuccessMessage={setSuccessMessage} setError={setError} />}
                {currentTab === 2 && <UserSettingsTab setSuccessMessage={setSuccessMessage} setError={setError} />}
            </Paper>

            <NotificationSnackbar
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                error={error}
                setError={setError}
            />
        </Box>
    );
};

export default UserProfile;
