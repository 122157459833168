import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, onAuthStateChanged, setPersistence, browserSessionPersistence, signOut } from 'firebase/auth';

// Async thunk to fetch the current user's token
export const fetchUserToken = createAsyncThunk('auth/fetchUserToken', async (_, { rejectWithValue }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    try {
      const token = await user.getIdToken(true);
      return { user, token };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  } else {
    return rejectWithValue('No user authenticated');
  }
});

// Async thunk to refresh the token
export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, { getState, rejectWithValue }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    try {
      const token = await user.getIdToken(true);
      return { token };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  } else {
    return rejectWithValue('No user authenticated');
  }
});

// Async thunk to log out the user
export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { rejectWithValue }) => {
  const auth = getAuth();
  try {
    await signOut(auth);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    token: null,
    status: 'idle',
    error: null,
    lastActivity: Date.now()
  },
  reducers: {
    setUser(state, action) {
      state.currentUser = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    updateLastActivity(state) {
      state.lastActivity = Date.now();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentUser = action.payload.user;
        state.token = action.payload.token;
        state.lastActivity = Date.now();
      })
      .addCase(fetchUserToken.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.lastActivity = Date.now();
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.currentUser = null;
        state.token = null;
        state.status = 'idle';
        state.lastActivity = Date.now();
      });
  }
});

export const { setUser, setToken, updateLastActivity } = authSlice.actions;

export default authSlice.reducer;

// Middleware to handle token refresh and idle logout
export const authMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState().auth;

  if (state.currentUser) {
    // Check if token needs refresh (e.g., every 55 minutes)
    const tokenAge = Date.now() - state.lastActivity;
    if (tokenAge > 55 * 60 * 1000) {
      store.dispatch(refreshToken());
    }

    // Check if user is idle (15 minutes)
    const idleTime = Date.now() - state.lastActivity;
    if (idleTime > 15 * 60 * 1000) {
      store.dispatch(logoutUser());
      // Redirect to homepage
      window.location.href = '/';
    }
  }

  return result;
};