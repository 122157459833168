import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Ensure AuthContext is correctly set up

const ProtectedRoute = () => {
  const { currentUser } = useAuth(); // Retrieve currentUser from the context

  if (!currentUser) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" replace />;
  }

  return <Outlet />; // Render the child routes if authenticated
};

export default ProtectedRoute;