import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const NotificationSnackbar = ({ successMessage, setSuccessMessage, error, setError }) => (
    <>
        <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
            <Alert severity="success">{successMessage}</Alert>
        </Snackbar>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert severity="error">{error}</Alert>
        </Snackbar>
    </>
);

export default NotificationSnackbar;
