const CONSTANTS = {
    MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
    VALID_IMAGE_TYPES: ['image/jpeg', 'image/png', 'image/webp'],
    MIN_NAME_LENGTH: 2,
    MIN_PASSWORD_LENGTH: 8,
    SUCCESS_MESSAGE_DURATION: 3000,
    NAVIGATION_DELAY: 1500,
    STEPS: ['Basic Information', 'Email Setup', 'Profile Picture']
};

export default CONSTANTS;
