import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveInvoice } from "../../../redux/invoiceSlice";
import {
  Paper,
  Box,
  Button,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportIcon from '@mui/icons-material/Report';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import createApi from "../api";
import ProductItem from "./ProductItem";
import SearchProductDialog from "../SearchProductDialog";
import SubmitOverviewDialog from "../SubmitOverviewDialog";
import EnhancedAddItemDialog from "../EnhancedAddItemDialog";
import { Prompt } from 'react-router-dom';

import InvoiceAppBar from "./InvoiceAppBar";
import InvoiceDetailsForm from "./InvoiceDetailsForm";

/**
 * Utility function to determine if an item is considered "complete."
 * This logic could be moved to a separate utility file if reused elsewhere.
 */
const isItemComplete = (item) => {
  return (
    item.description &&
    item.quantity &&
    item.packSize &&
    item.squareUp?.itemId
  );
};

const ProductsList = ({
  invoiceData,
  currentUser,
  docId,
  onNavigateToMain,
  onInvoiceDeleted,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ===== State Variables =====

  // Invoice fields
  const [items, setItems] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  // Invoice Status and Edit Tracking
  const [invoiceStatus, setInvoiceStatus] = useState("new");
  const isInvoiceDone = invoiceStatus === "done";

  // Dialog and Modal States
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [isOverviewDialogOpen, setIsOverviewDialogOpen] = useState(false);
  const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // Selected Item States for Searching/Editing
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [initialSearchTerm, setInitialSearchTerm] = useState("");
  const [currentPackSize, setCurrentPackSize] = useState("");

  // Loading and Error/Notification States
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  /**
   * Note: `error` is used to display both success and error messages via Snackbar.
   * Consider renaming or adding a separate state for success messages for clarity.
   */

  // Tracking Items that are incomplete before submission
  const [incompleteItems, setIncompleteItems] = useState([]);

  // Unsaved Changes and Save Status
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [lastSaved, setLastSaved] = useState(null);

  // Scroll Position
  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Menu Anchor for "Actions" menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // API instance creation
  const api = createApi(currentUser?.stsTokenManager?.accessToken);

  // ===== Effects and Hooks =====

  /**
   * Load invoice data into state when invoiceData changes.
   */
  useEffect(() => {
    if (invoiceData) {
      setItems(invoiceData.standardized.items || []);
      setVendorName(invoiceData.standardized.vendorName || "");
      setInvoiceNumber(invoiceData.standardized.invoiceNumber || "");

      if (invoiceData.standardized.invoiceDate) {
        const date = new Date(invoiceData.standardized.invoiceDate);
        setInvoiceDate(date.toISOString().split("T")[0]);
      }

      setInvoiceStatus(invoiceData.standardized.invoiceStatus || "new");
      setTotalAmount(invoiceData.standardized.invoiceTotal || 0);
    }
  }, [invoiceData]);

  /**
   * Restore scroll position after items or layout changes.
   */
  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  /**
   * Custom hook to prompt user if they have unsaved changes.
   * Currently inline, but could be moved to a separate hook file.
   */
  const useNavigationPrompt = (unsaved) => {
    useEffect(() => {
      const handleBeforeUnload = (e) => {
        if (unsaved) {
          e.preventDefault();
          e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        }
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [unsaved]);
  };

  useNavigationPrompt(hasUnsavedChanges);

  // ===== Handlers =====

  /**
   * Generic handler for text field changes in invoice-level fields (vendorName, invoiceNumber, etc.)
   */
  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
    setHasUnsavedChanges(true);
  };

  /**
   * Save the current scroll position. Useful before making updates that would re-render items.
   */
  const saveScrollPosition = useCallback(() => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  }, []);

  /**
   * Open/Close the actions menu.
   */
  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  /**
   * Placeholder: Delete zero quantity items logic.
   * Consider adding a confirmation dialog before actually deleting.
   */
  const handleDeleteZeroQty = () => {
    handleActionsClose();
    // TODO: Implement logic to remove items with zero quantity.
  };

  /**
   * Report invoice to some endpoint.
   * After reporting, navigates user away from the current page.
   */
  const handleReportInvoice = async () => {
    handleActionsClose();
    await api.updateInvoiceStatus(docId, "reported");
    navigate("/dashboard/invoices");
  };

  /**
   * Refresh invoice data from the backend.
   */
  const handleRefreshData = () => {
    handleActionsClose();
    // TODO: Implement refresh logic. Possibly re-fetch invoiceData from API.
  };

  /**
   * Handle saving the entire invoice.
   * This updates the backend with all current items and invoice fields.
   */
  const handleSaveInvoice = async () => {
    setIsLoading(true);
    setError(null);
    setSaveStatus('Saving...');

    // Calculate total from items
    const calculatedTotal = items.reduce((sum, item) => {
      return sum + (parseFloat(item.unitPrice) * parseInt(item.quantity, 10));
    }, 0);

    // Prepare updated invoice data to be saved
    const updatedInvoiceData = {
      ...invoiceData,
      actualAmount: calculatedTotal,
      customerName: invoiceData.customerName || "FIVE SPICE GROCERY",
      deliveryDate: invoiceData.deliveryDate || "",
      doc_id: docId,
      documentURL: invoiceData.documentURL,
      invoiceDate,
      invoiceNumber,
      invoiceStatus,
      invoiceTotal: calculatedTotal,
      items: items.map(item => ({
        ...item,
        unitPrice: parseFloat(item.unitPrice) || 0,
        quantity: parseInt(item.quantity, 10) || 0,
        packSize: parseInt(item.packSize, 10) || 1,
        totalPrice: (parseFloat(item.unitPrice) || 0) * (parseInt(item.quantity, 10) || 0)
      })),
      standardized: {
        ...invoiceData.standardized,
        vendorName,
        invoiceDate,
        invoiceNumber,
        invoiceTotal: calculatedTotal,
        items: items.map(item => ({
          ...item,
          unitPrice: parseFloat(item.unitPrice) || 0,
          quantity: parseInt(item.quantity, 10) || 0,
          packSize: parseInt(item.packSize, 10) || 1,
          totalPrice: (parseFloat(item.unitPrice) || 0) * (parseInt(item.quantity, 10) || 0)
        }))
      },
      vendorName,
      lastModified: new Date().toISOString(),
      modifiedBy: currentUser?.displayName || ""
    };

    try {
      await dispatch(saveInvoice(updatedInvoiceData)).unwrap();
      setHasUnsavedChanges(false);
      setLastSaved(new Date().toISOString());
      setSaveStatus('Saved');
      setError("Invoice successfully saved.");
    } catch (err) {
      console.error("Error saving invoice:", err);
      setSaveStatus('Save failed');
      setError("Failed to save invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle item updates from `ProductItem`.
   * This includes saving item changes to the backend and updating product price in Square.
   */
  const handleUpdateItem = useCallback(
    async ({ invoiceId, itemIndex, updatedItemData }) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot update items");
        return;
      }

      saveScrollPosition();
      setIsLoading(true);
      setError(null);
      try {
        const result = await api.updateItem(invoiceId, itemIndex, updatedItemData);

        await api.updateProductPrice(
          updatedItemData.squareUp.variationId,
          updatedItemData.squareUp.price,
          updatedItemData.squareUp.categoryId
        );

        setItems((prevItems) => {
          const newItems = [...prevItems];
          newItems[itemIndex] = result.updatedItemTableDoc;
          return newItems;
        });
      } catch (err) {
        console.error("Error updating item:", err);
        setError("Failed to update item. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [api, invoiceStatus, saveScrollPosition]
  );

  /**
   * Opens the product search dialog to match an item with a Square product.
   */
  const handleOpenSearch = useCallback(
    ({ itemIndex, initialSearchTerm, currentPackSize }) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot open search");
        return;
      }

      setSelectedItemIndex(itemIndex);
      setInitialSearchTerm(initialSearchTerm);
      setCurrentPackSize(currentPackSize);
      setIsSearchDialogOpen(true);
    },
    [invoiceStatus]
  );

  /**
   * Called after selecting a product from the search dialog. Updates the item with Square details.
   */
  const handleSelectSquareProduct = useCallback(
    (squareProduct) => {
      if (invoiceStatus === "done") return;

      saveScrollPosition();
      setItems((prevItems) => {
        const newItems = [...prevItems];
        const selectedItem = newItems[selectedItemIndex];

        const updatedItem = {
          ...selectedItem,
          squareUp: {
            categoryId: squareProduct.categoryId,
            itemId: squareProduct.id,
            itemName: squareProduct.name,
            variationId: squareProduct.variations?.[0]?.id || "",
            variationName: squareProduct.variations?.[0]?.name || "",
            price: squareProduct.variations?.[0]?.price_money?.amount || 0,
            barcode: squareProduct.barcode || squareProduct.sku || "",
          },
          // Preserve current pack size if not provided by search result
          packSize: selectedItem.packSize || currentPackSize,
        };

        newItems[selectedItemIndex] = updatedItem;
        return newItems;
      });

      setIsSearchDialogOpen(false);
    },
    [selectedItemIndex, invoiceStatus, saveScrollPosition, currentPackSize]
  );

  /**
   * Deletes a single item. If the last item is deleted, the entire invoice is deleted.
   * Consider adding a confirmation dialog here as well.
   */
  const handleDeleteItem = useCallback(
    async ({ itemIndex }) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot delete item");
        return;
      }

      saveScrollPosition();
      setIsLoading(true);
      setError(null);

      setItems((prevItems) => {
        const updatedItems = prevItems.filter((_, i) => i !== itemIndex);

        // If no items left, delete the invoice
        if (updatedItems.length === 0) {
          api.deleteInvoice(docId, dispatch)
            .then(() => {
              setError("Last item deleted. Invoice has been removed.");
              if (onInvoiceDeleted) onInvoiceDeleted();
            })
            .catch((err) => {
              console.error("Error deleting invoice:", err);
              setError(`Failed to delete invoice: ${err.message}`);
            });
        }

        return updatedItems;
      });

      // Update incomplete items array since we removed an item
      setIncompleteItems((prevIncomplete) => {
        return prevIncomplete
          .filter((i) => i !== itemIndex)
          .map((i) => (i > itemIndex ? i - 1 : i));
      });

      try {
        await api.deleteItem(docId, itemIndex);
        setError("Item successfully deleted");
      } catch (err) {
        console.error("Error deleting item via API:", err);
        setError(`Failed to delete item: ${err.message}. Please try again.`);
      } finally {
        setIsLoading(false);
      }
    },
    [api, docId, invoiceStatus, saveScrollPosition, onInvoiceDeleted, dispatch]
  );

  /**
   * Deletes the entire invoice after confirmation.
   */
  const handleDeleteInvoice = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await api.deleteInvoice(docId, dispatch);
      setError("Invoice successfully deleted");
      if (onInvoiceDeleted) onInvoiceDeleted();
    } catch (err) {
      console.error("Error deleting invoice:", err);
      setError("Failed to delete invoice. Please try again.");
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  /**
   * Adds a new item from the add item dialog.
   * Consider validating the item before adding it.
   */
  const handleAddItem = useCallback(
    (newItem) => {
      saveScrollPosition();
      const formattedItem = {
        description: newItem.description || '',
        quantity: parseFloat(newItem.quantity) || 0,
        unitPrice: parseFloat(newItem.unitPrice) || 0,
        packSize: newItem.packSize || '',
        totalPrice: (parseFloat(newItem.quantity) * parseFloat(newItem.unitPrice)) || 0,
        squareUp: newItem.squareUp || {
          itemId: '',
          itemName: '',
          variationId: '',
          variationName: '',
          price: 0,
          barcode: ''
        }
      };

      setItems((prevItems) => [...prevItems, formattedItem]);
      setIsAddItemDialogOpen(false);
      setHasUnsavedChanges(true);
    },
    [saveScrollPosition]
  );

  /**
   * Handle the confirmation of submitting the invoice to Square.
   * If successful, sets the invoice to done and navigates away.
   */
  const handleConfirmSubmitToSquare = async () => {
    setIsLoading(true);
    setError(null);
    const currentDate = new Date().toISOString();

    try {
      const invoiceDataForSquare = {
        docId,
        vendorName,
        invoiceDate,
        invoiceNumber,
        totalAmount,
        items: items.map((item) => ({
          ...item,
          unitPrice: parseFloat(item.unitPrice),
          quantity: parseInt(item.quantity, 10),
          packSize: parseInt(item.packSize, 10) || 1,
        })),
        submittedBy: currentUser.displayName,
        submittedOn: currentDate
      };

      await api.submitInvoiceToSquare(invoiceDataForSquare, currentUser);
      await api.updateInvoiceStatus(docId, "done");

      setInvoiceStatus("done");
      setError("Invoice successfully submitted to Square and marked as done");
      setIncompleteItems([]);
      setIsOverviewDialogOpen(false);
      navigate("/dashboard/invoices");
    } catch (err) {
      console.error("Error submitting invoice to Square or updating status:", err);
      setError("Failed to submit invoice to Square or update status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Validate items and show the overview dialog before submitting to Square.
   */
  const handleSubmitToSquare = useCallback(() => {
    if (invoiceStatus === "done") {
      console.log("Invoice is already submitted and marked as done");
      return;
    }

    // Check for incomplete items before allowing submission
    const incomplete = items.reduce((acc, item, index) => {
      if (!isItemComplete(item)) acc.push(index);
      return acc;
    }, []);

    if (incomplete.length > 0) {
      setIncompleteItems(incomplete);
      setError(
        `Please complete all item details before submitting. Incomplete items: ${incomplete
          .map((i) => i + 1)
          .join(", ")}`
      );
      return;
    }

    setIsOverviewDialogOpen(true);
  }, [items, invoiceStatus]);

  // ===== Render =====

  return (
    <Paper
      elevation={3}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      {/* Top App Bar with Save status and actions */}
      <InvoiceAppBar
        saveStatus={saveStatus}
        lastSaved={lastSaved}
        hasUnsavedChanges={hasUnsavedChanges}
        handleSaveInvoice={handleSaveInvoice}
        isLoading={false}
      />

      {/* Invoice Details Form */}
      <Box p={2}>
        <InvoiceDetailsForm
          vendorName={vendorName}
          setVendorName={setVendorName}
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
          isInvoiceDone={isInvoiceDone}
          handleFieldChange={handleFieldChange}
        />
      </Box>

      {/* Items List */}
      <Box
        ref={scrollContainerRef}
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          px: 2,
        }}
      >
        {items.map((item, index) => (
          <ProductItem
            key={`${item.id}-${index}`} // Uniqueness: combine ID and index
            item={item}
            invoiceId={docId}
            itemIndex={index}
            onSearch={handleOpenSearch}
            onUpdate={handleUpdateItem}
            onDelete={handleDeleteItem}
            isIncomplete={incompleteItems.includes(index)}
            disabled={isInvoiceDone}
          />
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Bottom Action Buttons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2, mt: 2 }}
      >
        {/* Left Actions: Submit to Square */}
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={handleSubmitToSquare}
            disabled={isLoading || isInvoiceDone}
            startIcon={<SendIcon />}
          >
            Submit to Square
          </Button>
        </Box>

        {/* Right Actions: More Menu */}
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleActionsClick}
            startIcon={<MoreVertIcon />}
            disabled={isLoading}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleActionsClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            {hasUnsavedChanges && (
              <MenuItem onClick={() => { handleSaveInvoice(); handleActionsClose(); }}>
                <ListItemIcon>
                  <SaveIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Save Invoice</ListItemText>
              </MenuItem>
            )}

            <MenuItem onClick={() => { setIsAddItemDialogOpen(true); handleActionsClose(); }}>
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add Item</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => { setIsDeleteDialogOpen(true); handleActionsClose(); }}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete Invoice</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleDeleteZeroQty}>
              <ListItemIcon>
                <RemoveCircleOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete Zero Qty Items</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleReportInvoice}>
              <ListItemIcon>
                <ReportIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Report Invoice</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleRefreshData}>
              <ListItemIcon>
                <RefreshIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Refresh Invoice Data</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Dialogs and Overlays */}
      <SearchProductDialog
        open={isSearchDialogOpen}
        onClose={() => setIsSearchDialogOpen(false)}
        onSelect={handleSelectSquareProduct}
        api={api}
        isLoading={isLoading}
        initialSearchTerm={initialSearchTerm}
      />
      <EnhancedAddItemDialog
        open={isAddItemDialogOpen}
        onClose={() => setIsAddItemDialogOpen(false)}
        onAddItem={handleAddItem}
        onSelect={handleSelectSquareProduct}
        api={api}
        currentUser={currentUser}
        docId={docId}
        initialSearchTerm=""
        isLoading={isLoading}
      />

      <SubmitOverviewDialog
        open={isOverviewDialogOpen}
        onClose={() => setIsOverviewDialogOpen(false)}
        items={items}
        invoiceTotal={totalAmount}
        onConfirm={handleConfirmSubmitToSquare}
      />

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this invoice? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteInvoice} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert
          onClose={() => setError(null)}
          severity={
            error && error.toLowerCase().includes("successfully")
              ? "success"
              : "error"
          }
        >
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default React.memo(ProductsList);
