import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import FlyerGenerator from './FlyerGenerator';
import createApi from '../api';
import AddSaleItems from './AddSaleItems';

const SalesTool = ({ token }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [saleName, setSaleName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [headerInfo, setHeaderInfo] = useState('');
  const [saleItems, setSaleItems] = useState([]);
  const [flyerBackground, setFlyerBackground] = useState('#ffffff');
  const [flyerFont, setFlyerFont] = useState('Arial');
  const [storeLogo, setStoreLogo] = useState(null);
  const [storeImage, setStoreImage] = useState(null);
  const [storeContact, setStoreContact] = useState({
    address: "680 River Oaks Parkway, Unit P, San Jose, CA 95143",
    phone: "(408) 123-4567",
    hours: "9am to 9pm, Everyday",
  });
  const [openFlyer, setOpenFlyer] = useState(false);
  const [api, setApi] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setApi(createApi(token));
  }, [token]);

  const steps = ['Sale Details', 'Add Items', 'Customize Flyer', 'Preview & Export'];

  const validateStep = () => {
    setError('');
    switch (activeStep) {
      case 0:
        if (!saleName.trim() || !startDate || !endDate || !headerInfo.trim()) {
          setError('Please complete all fields in this step');
          return false;
        }
        break;
      case 1:
        if (saleItems.length === 0) {
          setError('Please add at least one item to the sale');
          return false;
        }
        break;
      case 2:
        if (!storeLogo || !storeImage) {
          setError('Please upload both a logo and a store image');
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGenerateFlyer = () => {
    setOpenFlyer(true);
  };

  const handleBackgroundColorChange = (color) => {
    setFlyerBackground(color.hex);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        {activeStep === 0 && (
          <Box>
            <Typography variant="h5" gutterBottom>Step 1: Sale Details</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Sale Name"
                  value={saleName}
                  onChange={(e) => setSaleName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Sale Description"
                  multiline
                  rows={3}
                  value={headerInfo}
                  onChange={(e) => setHeaderInfo(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {activeStep === 1 && (
          <Box>
            <Typography variant="h5" gutterBottom>Step 2: Add Sale Items</Typography>
            <AddSaleItems api={api} saleItems={saleItems} setSaleItems={setSaleItems} />
          </Box>
        )}

        {activeStep === 2 && (
          <Box>
            <Typography variant="h5" gutterBottom>Step 3: Customize Flyer</Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography gutterBottom>Background Color</Typography>
                <ChromePicker
                  color={flyerBackground}
                  onChange={handleBackgroundColorChange}
                  disableAlpha
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Font"
                  value={flyerFont}
                  onChange={(e) => setFlyerFont(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Store Logo"
                  type="file"
                  onChange={(e) => setStoreLogo(URL.createObjectURL(e.target.files[0]))}
                  helperText="Upload your store logo"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Store Image"
                  type="file"
                  onChange={(e) => setStoreImage(URL.createObjectURL(e.target.files[0]))}
                  helperText="Upload a store image"
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {activeStep === 3 && (
          <Box>
            <Typography variant="h5" gutterBottom>Step 4: Preview & Export</Typography>
            <Button variant="contained" color="primary" onClick={handleGenerateFlyer} sx={{ mt: 3 }}>
              Preview Flyer
            </Button>
            <FlyerGenerator
              open={openFlyer}
              onClose={() => setOpenFlyer(false)}
              saleName={saleName}
              startDate={startDate}
              endDate={endDate}
              headerInfo={headerInfo}
              saleItems={saleItems}
              flyerBackground={flyerBackground}
              flyerFont={flyerFont}
              storeLogo={storeLogo}
              storeImage={storeImage}
              storeContact={storeContact}
            />
          </Box>
        )}
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={activeStep === 3 ? handleGenerateFlyer : handleNext}
        >
          {activeStep === 3 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Box>
  );
};

export default SalesTool;
