// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsD5Z21_0CnXFOUl49cKp8OyMpt9t58j8",
  authDomain: "fsigcustomerapp.firebaseapp.com",
  projectId: "fsigcustomerapp",
  storageBucket: "fsigcustomerapp.appspot.com",
  messagingSenderId: "545255778774",
  appId: "1:545255778774:web:8f803fd81aacd264909c77"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

//Initialize Storage 
const storage = getStorage(app)

export { app, db, storage };