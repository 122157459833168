import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Box,
  Avatar,
  Button,
  Paper
} from '@mui/material';

const FlyerGenerator = ({
  open,
  onClose,
  saleName,
  startDate,
  endDate,
  headerInfo,
  produceItems,
  groceryItems,
  flyerBackground,
  flyerFont,
  storeLogo,
  storeImage,
  storeContact
}) => {
  const defaultLogo = 'https://872e685f4ca9eeb70124.cdn6.editmysite.com/uploads/b/872e685f4ca9eeb701242cdf8cd51292d7d6d3050343895dd3c1679fc009be63/Horizontal%20Logo%20%281%29_1721003790.png?width=2400&optimize=medium'; // Update with actual logo path
  const defaultImage = 'https://lh3.googleusercontent.com/p/AF1QipPAuf0w9OVPy9IIrJNr5tzvX4ZT5kasosbZLAdX=s1360-w1360-h1020'; // Placeholder for missing images

  const renderProductCard = (item) => (
    <Paper
      elevation={3}
      sx={{
        p: 1,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
      }}
    >
      <Avatar
        src={item.imageUrls?.[0] || defaultImage}
        alt={item.itemName}
        sx={{ width: 80, height: 80, mb: 1 }}
        variant="square"
      />
      <Typography variant="subtitle1" fontWeight="bold">
        {item.itemName}
      </Typography>
      <Typography variant="body2">{item.variationName}</Typography>
      <Typography variant="h6" color="secondary">
        ${item.salePrice.toFixed(2)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Original: ${item.price.toFixed(2)}
      </Typography>
    </Paper>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h4" align="center" gutterBottom>
          Weekend Sale
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: flyerBackground,
          fontFamily: flyerFont,
          padding: 3,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        {/* Header Section */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box>
            <Typography variant="h5">{saleName || "Weekend Sale"}</Typography>
            <Typography variant="subtitle1">
              {new Date(startDate).toLocaleDateString()} - {new Date(endDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body2">{headerInfo}</Typography>
            <Typography variant="body2" mt={2}>
              {storeContact.address}
            </Typography>
            <Typography variant="body2">{storeContact.phone}</Typography>
            <Typography variant="body2">{storeContact.hours}</Typography>
            {/* Add email and website if needed */}
          </Box>
          <Box>
            <Avatar
              src={storeLogo || defaultLogo}
              alt="Store Logo"
              sx={{ width: 100, height: 100 }}
              variant="square"
            />
          </Box>
        </Box>

        {/* Store Image */}
        {storeImage && (
          <Box mb={3} textAlign="center">
            <Avatar src={storeImage} alt="Store" sx={{ width: '100%', height: 200, borderRadius: 2 }} variant="square" />
          </Box>
        )}

        {/* Front Page (3x3 Produce Items) */}
        <Typography variant="h5" align="center" gutterBottom>
          Featured Produce Items
        </Typography>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          {produceItems.slice(0, 9).map((item) => (
            <Grid item xs={4} key={item.variationId}>
              {renderProductCard(item)}
            </Grid>
          ))}
        </Grid>

        {/* Back Page (3x5 Grocery Items) */}
        <Typography variant="h5" align="center" gutterBottom>
          General Grocery Deals
        </Typography>
        <Grid container spacing={2}>
          {groceryItems.slice(0, 15).map((item) => (
            <Grid item xs={4} key={item.variationId}>
              {renderProductCard(item)}
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button variant="contained" onClick={() => window.print()}>Print Flyer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlyerGenerator;
