import React, { useState, useMemo } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom'; // For navigation
import createApi from '../api';
import ProductList from './ProductList'; // Importing the ProductList component

const ProductManagement = ({ currentUser }) => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser]);

  const handleSearch = async () => {
    if (!query.trim()) return; // Prevent empty searches
    try {
      setLoading(true);
      const results = await api.searchSquareProducts(query);
      setSearchResults(results);
    } catch (error) {
      console.error('Failed to search products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (product) => {
    navigate(`/dashboard/product/${product.variationId}`, { state: { product } }); // Navigate to ProductDetails
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Product Management
      </Typography>

      {/* Search Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 4,
          backgroundColor: 'white',
          padding: 2,
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <TextField
          fullWidth
          placeholder="Search by product name, SKU, or identifier"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          startIcon={<SearchIcon />}
          disabled={loading}
        >
          Search
        </Button>
      </Box>

      {/* Loading State */}
      {loading && (
        <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Display Product List */}
      {!loading && searchResults.length > 0 && (
        <ProductList
          products={searchResults}
          onProductSelect={handleViewDetails}
        />
      )}

      {/* No Results */}
      {!loading && searchResults.length === 0 && query && (
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          sx={{ mt: 4 }}
        >
          No products found for "<strong>{query}</strong>"
        </Typography>
      )}
    </Container>
  );
};

export default ProductManagement;
