import React, { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../context/AuthContext';

const UserContactTab = ({ setSuccessMessage, setError }) => {
    const { currentUser } = useAuth();
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const userDoc = doc(db, 'users', currentUser.uid);
                const docSnapshot = await getDoc(userDoc);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setPhone(data.phone || '');
                    setAddress(data.address || '');
                }
            } catch (err) {
                setError('Failed to load contact info.');
            }
        };
        fetchContactInfo();
    }, [currentUser, setError]);

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const userDoc = doc(db, 'users', currentUser.uid);
            await setDoc(userDoc, { phone, address }, { merge: true });
            setSuccessMessage('Contact info updated successfully!');
        } catch (err) {
            setError('Failed to update contact info.');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <TextField
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isSaving}
                fullWidth
            >
                {isSaving ? <CircularProgress size={24} /> : 'Save Changes'}
            </Button>
        </>
    );
};

export default UserContactTab;
