// styles.js
export const invoicePageStyles = {
    container: {
      width: '100%',
      margin: '0 auto',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      minHeight: '100vh',
      backgroundColor: '#f5f5f5'
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px'
    },
    errorContainer: {
      padding: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px'
    }
  };