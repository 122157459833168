import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Typography, Grid, Box, TextField, Button, Card, CardContent, InputAdornment, Divider, Snackbar, Alert, Tooltip, Paper, MenuItem, CircularProgress, Autocomplete, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CategoryIcon from '@mui/icons-material/Category';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CheckCircleOutline } from '@mui/icons-material';
import createApi from '../api';
import { calculatePrices, calculatePriceDifference, getRecommendation } from './priceCalculator';

const categoryCache = {};

const ProductItem = React.memo(({
  item,
  invoiceId,
  itemIndex,
  onSearch,
  onUpdate,
  onDelete,
  disabled,
  isLoading,
  currentUser
}) => {
  const api = createApi(currentUser?.stsTokenManager?.accessToken);

  const [localItem, setLocalItem] = useState(item || {});
  const [categoryData, setCategoryData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [localPrice, setLocalPrice] = useState(item?.squareUp?.price || 0);
  const [calculatedPrice, setCalculatedPrice] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);

  const [confirmNotReceivedOpen, setConfirmNotReceivedOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [errors, setErrors] = useState({ description: false, quantity: false, unitPrice: false, packSize: false });

  useEffect(() => {
    setIsCategoriesLoading(true);
    (async () => {
      try {
        const response = await api.getCategories();
        const fetchedCategories = response || [];
        setCategories(fetchedCategories);
        fetchedCategories.forEach((category) => {
          categoryCache[category.squareId] = category;
        });
      } catch (error) {
        console.error('Error fetching categories:', error.message || error.response);
      } finally {
        setIsCategoriesLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setLocalItem(prevLocalItem => ({
      ...item,
      packSize: prevLocalItem.packSize || item.packSize,
    }));
    setLocalPrice(item?.squareUp?.price ? item.squareUp.price : 0);
  }, [item]);

  useEffect(() => {
    const categoryId = localItem?.squareUp?.categoryId;
    if (categoryId && categoryCache[categoryId]) {
      setCategoryData(categoryCache[categoryId]);
    } else {
      setCategoryData(null);
    }
  }, [localItem?.squareUp?.categoryId]);

  const handleInputChange = useCallback((field, isSquareUpdate = false) => (event) => {
    const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
    setLocalItem(prev => {
      if (isSquareUpdate) {
        return { ...prev, squareUp: { ...prev.squareUp, [field]: value } };
      }
      return { ...prev, [field]: value };
    });
    // Clear error for that field if user is now editing it
    setErrors(prev => ({ ...prev, [field]: false }));
  }, []);

  const handleSave = useCallback(async () => {
    if (disabled) return;

    const missingFields = [];
    const newErrors = { description: false, quantity: false, unitPrice: false, packSize: false };

    if (!localItem.description) { missingFields.push('Description'); newErrors.description = true; }
    if (localItem.quantity == null) { missingFields.push('Quantity'); newErrors.quantity = true; }
    if (localItem.unitPrice == null) { missingFields.push('Unit Price'); newErrors.unitPrice = true; }
    if (!localItem.packSize) { missingFields.push('Pack Size'); newErrors.packSize = true; }

    setErrors(newErrors);

    if (missingFields.length > 0) {
      setSnackbarMessage(`Please fill out: ${missingFields.join(', ')}`);
      setSnackbarOpen(true);
      return;
    }

    try {
      const updatedItemData = {
        ...localItem,
        squareUp: {
          ...localItem.squareUp,
          price: localPrice
        }
      };

      await onUpdate({ invoiceId, itemIndex, updatedItemData });
      setSnackbarMessage('Item updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating item:', error);
      setSnackbarMessage('Failed to update item. Please try again.');
      setSnackbarOpen(true);
    }
  }, [disabled, localItem, localPrice, onUpdate, invoiceId, itemIndex]);

  const handleMatch = useCallback(async () => {
    try {
      const searchResult = await onSearch({
        invoiceId,
        itemIndex,
        initialSearchTerm: localItem.squareUp?.barcode || localItem.description,
        currentPackSize: localItem.packSize
      });

      if (searchResult && Object.keys(searchResult).length > 0) {
        setLocalItem(prev => ({
          ...prev,
          ...searchResult,
          packSize: prev.packSize || searchResult.packSize
        }));
        setSnackbarMessage('Match found and item updated');
      } else {
        setSnackbarMessage('No match found for the item');
      }
    } catch (error) {
      console.error('Error during match:', error);
      setSnackbarMessage('An error occurred while finding the product. Please try again.');
    }
    setSnackbarOpen(true);
  }, [onSearch, invoiceId, itemIndex, localItem]);

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    try {
      await onUpdate({
        invoiceId,
        itemIndex,
        updatedItemData: {
          ...localItem,
          squareUp: {
            ...localItem.squareUp,
            categoryId: selectedCategoryId,
          },
        },
      });
      setLocalItem((prev) => ({
        ...prev,
        squareUp: {
          ...prev.squareUp,
          categoryId: selectedCategoryId,
        },
      }));
      setSnackbarMessage('Category updated successfully');
    } catch (error) {
      console.error('Error updating category:', error.message || error.response);
      setSnackbarMessage('Failed to update category. Please try again.');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const formatPrice = useCallback((price) => {
    if (price < 100) {
      return `0.${price.toString().padStart(2, "0")}`;
    }
    const dollars = Math.floor(price / 100);
    const cents = price % 100;
    return `${dollars}.${cents.toString().padStart(2, "0")}`;
  }, []);

  const costPerUnit = useMemo(() => {
    const packSizeNum = parseFloat(localItem.packSize) || 1;
    return packSizeNum > 0 ? (localItem.unitPrice || 0) / packSizeNum : (localItem.unitPrice || 0);
  }, [localItem.packSize, localItem.unitPrice]);

  const costPerPiece = useMemo(() => {
    const packSizeNum = parseFloat(localItem.packSize) || 1;
    return (localItem.unitPrice || 0) / packSizeNum;
  }, [localItem.unitPrice, localItem.packSize]);

  const handleTooltipOpen = useCallback(() => {
    const price = calculatePrices(localItem, categoryData);
    setCalculatedPrice(price);
  }, [localItem, categoryData]);

  const squarePriceInDollars = useMemo(
    () => localItem.squareUp?.price ? localItem.squareUp.price / 100 : null,
    [localItem.squareUp?.price]
  );

  const priceDifference = useMemo(
    () => calculatePriceDifference(squarePriceInDollars, calculatedPrice),
    [squarePriceInDollars, calculatedPrice]
  );
  const recommendation = useMemo(() => getRecommendation(priceDifference), [priceDifference]);

  const hasSquareMatch = useMemo(
    () => localItem?.squareUp?.itemId && localItem?.squareUp?.variationId,
    [localItem?.squareUp]
  );

  const isItemComplete = useMemo(() => {
    return (
      localItem.description &&
      localItem.quantity != null &&
      localItem.unitPrice != null &&
      localItem.packSize &&
      hasSquareMatch
    );
  }, [localItem, hasSquareMatch]);

  const handleConfirmNotReceived = useCallback(async () => {
    try {
      const updatedItemData = { ...localItem, notReceived: true };
      await onUpdate({ invoiceId, itemIndex, updatedItemData });
      setLocalItem(updatedItemData);
      setSnackbarMessage('Item marked as not received');
    } catch (error) {
      console.error('Error marking item as not received:', error);
      setSnackbarMessage('Failed to mark item as not received. Please try again.');
    } finally {
      setSnackbarOpen(true);
      setConfirmNotReceivedOpen(false);
    }
  }, [localItem, onUpdate, invoiceId, itemIndex]);

  const handleConfirmDelete = useCallback(async () => {
    try {
      await onDelete({ invoiceId, itemIndex });
    } catch (error) {
      console.error('Error deleting item:', error);
      setSnackbarMessage('Failed to delete item. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setConfirmDeleteOpen(false);
    }
  }, [onDelete, invoiceId, itemIndex]);

  // Color coding if price difference is large
  const priceDiffColor = useMemo(() => {
    if (calculatedPrice === null || localPrice === 0) return 'inherit';
    const actualDollars = localPrice / 100;
    const diff = Math.abs(actualDollars - calculatedPrice);
    return diff > 2 ? 'warning.main' : 'inherit';
  }, [calculatedPrice, localPrice]);

  return (
    <Card
      elevation={2}
      sx={{
        mb: 2,
        borderRadius: 2,
        border: '1px solid',
        borderColor: isItemComplete ? 'success.light' : 'warning.light',
        opacity: disabled ? 0.7 : 1,
        maxWidth: '100%',
        '&:hover': { boxShadow: 3 },
        p: 1
      }}
    >
      <CardContent sx={{ p: 2 }}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FiberManualRecordIcon
              sx={{
                color: isItemComplete ? 'success.main' : 'warning.main',
                fontSize: 12
              }}
            />
            <Typography variant="subtitle1" fontWeight={500}>
              {localItem.description || 'No Description'}
            </Typography>
          </Stack>

          {hasSquareMatch ? (
            <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
              <CheckCircleOutline sx={{ fontSize: 16, color: 'success.main' }} />
              <Typography variant="body2" color="text.secondary">
                {`${localItem.squareUp?.itemName} - ${localItem.squareUp?.variationName}`}
              </Typography>
              {localItem.squareUp?.barcode && (
                <Typography variant="caption" color="text.secondary">
                  | Barcode: {localItem.squareUp.barcode}
                </Typography>
              )}
            </Box>
          ) : (
            <Typography variant="body2" color="warning.main">
              No Square Item Matched
            </Typography>
          )}

          <Divider />

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Paper
                elevation={0}
                sx={{
                  p: 1.5,
                  bgcolor: 'grey.50',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'grey.200'
                }}
              >
                <Typography variant="body2" fontWeight="bold" color="text.secondary" sx={{ mb:0.5 }}>
                  Pricing Summary
                </Typography>
                <Typography variant="body2" display="flex" justifyContent="space-between">
                  <span>Total:</span>
                  <span>${(localItem.quantity * localItem.unitPrice).toFixed(2)}</span>
                </Typography>
                <Typography variant="body2" display="flex" justifyContent="space-between">
                  <span>Per Unit:</span>
                  <span>${costPerUnit.toFixed(2)}</span>
                </Typography>
                <Typography variant="body2" display="flex" justifyContent="space-between">
                  <span>Per Piece:</span>
                  <span>${costPerPiece.toFixed(2)}</span>
                </Typography>
              </Paper>
            </Grid>

            {hasSquareMatch && (
              <Grid item xs={12} md={8}>
                <Autocomplete
                  value={categories.find(cat => cat.squareId === localItem?.squareUp?.categoryId) || null}
                  onChange={(event, newValue) => {
                    handleCategoryChange({ target: { value: newValue ? newValue.squareId : '' }});
                  }}
                  options={categories.slice().sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(option) => option.name || ''}
                  loading={isCategoriesLoading}
                  fullWidth
                  disableClearable
                  disabled={disabled}
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <CategoryIcon sx={{ ml: 1, mr: 1, color: 'action.active' }} />
                        ),
                        endAdornment: (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {isCategoriesLoading && <CircularProgress size={20} />}
                            {params.InputProps.endAdornment}
                          </Box>
                        ),
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2,
                          backgroundColor: '#f9f9f9',
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                          '&.Mui-focused': {
                            backgroundColor: '#ffffff',
                          }
                        }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      sx={{
                        py: 1,
                        px: 2,
                        '&:hover': {
                          backgroundColor: 'action.hover',
                        }
                      }}
                    >
                      <Typography>{option.name}</Typography>
                    </MenuItem>
                  )}
                  noOptionsText={
                    <Typography color="text.secondary" sx={{ p: 1 }}>
                      {categories.length === 0 ? "No categories" : "No matching categories"}
                    </Typography>
                  }
                  ListboxProps={{
                    sx: {
                      maxHeight: 250,
                      '& .MuiAutocomplete-listbox': {
                        padding: 0,
                      }
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Divider />

          <Grid container spacing={1}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Qty"
                type="number"
                value={localItem.quantity || ''}
                onChange={handleInputChange('quantity')}
                disabled={disabled}
                fullWidth
                size="small"
                error={errors.quantity}
                helperText={errors.quantity ? 'Required' : ''}
                sx={{ '& .MuiOutlinedInput-root': { backgroundColor: 'background.paper' } }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Pack"
                value={localItem.packSize || ''}
                onChange={handleInputChange('packSize')}
                disabled={disabled}
                fullWidth
                size="small"
                error={errors.packSize}
                helperText={errors.packSize ? 'Required' : ''}
                sx={{ '& .MuiOutlinedInput-root': { backgroundColor: 'background.paper' } }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Unit $"
                type="number"
                value={localItem.unitPrice || ''}
                onChange={handleInputChange('unitPrice')}
                disabled={disabled}
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                error={errors.unitPrice}
                helperText={errors.unitPrice ? 'Required' : ''}
                sx={{ '& .MuiOutlinedInput-root': { backgroundColor: 'background.paper' } }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Tooltip
                title="Sale price for customers. Hover to recalc recommended."
                onOpen={handleTooltipOpen}
              >
                <TextField
                  label="Sale $"
                  value={localPrice !== '' ? formatPrice(localPrice) : ''}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    setLocalPrice(value ? Number(value) : '');
                  }}
                  disabled={disabled}
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'background.paper'
                    },
                    color: priceDiffColor
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>

          {calculatedPrice !== null && (
            <Typography variant="caption" sx={{ color: priceDiffColor }}>
              {recommendation} (You: ${formatPrice(localPrice)}, Rec: ${calculatedPrice.toFixed(2)})
            </Typography>
          )}

          <Divider />

          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSave}
              disabled={disabled || isLoading}
              sx={{ textTransform: 'none' }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="info"
              startIcon={<SearchIcon />}
              onClick={handleMatch}
              disabled={disabled}
              sx={{ textTransform: 'none' }}
            >
              Find Product
            </Button>
            <Button
              variant="outlined"
              color="warning"
              onClick={() => setConfirmNotReceivedOpen(true)}
              disabled={disabled}
              sx={{ textTransform: 'none' }}
            >
              Not Received
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setConfirmDeleteOpen(true)}
              disabled={disabled}
              sx={{ textTransform: 'none' }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </CardContent>

      {/* Confirm Not Received Dialog */}
      <Dialog
        open={confirmNotReceivedOpen}
        onClose={() => setConfirmNotReceivedOpen(false)}
      >
        <DialogTitle>Mark Not Received</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to mark this item as not received?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmNotReceivedOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmNotReceived} color="warning">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Delete Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This cannot be undone. Delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('successfully') || snackbarMessage.includes('marked as not received') ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
});

export default ProductItem;
