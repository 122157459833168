import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HowTo = () => {
    const howToSections = [
        {
            title: 'Getting Started',
            content: 'Learn how to set up your account, navigate the dashboard, and start using the application effectively.',
            videoUrl: 'https://www.youtube.com/embed/example1' // Replace with actual video URL
        },
        {
            title: 'Managing Invoices',
            content: 'This section provides detailed instructions on how to create, edit, and track your invoices.',
            videoUrl: 'https://www.youtube.com/embed/example2'
        },
        {
            title: 'Updating Prices',
            content: 'Follow these steps to update product prices using the Price Updater tool.',
            videoUrl: 'https://www.youtube.com/embed/example3'
        },
        {
            title: 'Using the Sales Tool',
            content: 'Understand how to leverage the Sales Tool to track and optimize your sales performance.',
            videoUrl: 'https://www.youtube.com/embed/example4'
        },
        {
            title: 'Adding Vendors',
            content: 'Learn how to add and manage vendors in the system for efficient inventory management.',
            videoUrl: 'https://www.youtube.com/embed/example5'
        },
        {
            title: 'Customizing Categories',
            content: 'Instructions on how to create, edit, and organize product categories.',
            videoUrl: 'https://www.youtube.com/embed/example6'
        },
        {
            title: 'User Profile Management',
            content: 'Steps to update your profile, change your password, and manage account settings.',
            videoUrl: 'https://www.youtube.com/embed/example7'
        }
    ];

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Typography variant="h4" gutterBottom>
                How To Use This App
            </Typography>
            <Typography variant="body1" paragraph>
                This page provides step-by-step instructions to help you navigate and make the most out of the app.
            </Typography>
            <Box>
                {howToSections.map((section, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">{section.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" paragraph>
                                {section.content}
                            </Typography>
                            {section.videoUrl && (
                                <Box sx={{ mt: 2 }}>
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={section.videoUrl}
                                        title={section.title}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
};

export default HowTo;
