import React, { useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/Info';

const ProductList = ({ products, onProductSelect }) => {
  const [openProducts, setOpenProducts] = useState({});

  // Group products by productId
  const groupedProducts = products.reduce((acc, product) => {
    if (!acc[product.productId]) {
      acc[product.productId] = [];
    }
    acc[product.productId].push(product);
    return acc;
  }, {});

  const toggleProduct = (productId) => {
    setOpenProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Search Results
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Stock Level</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedProducts).map(([productId, variations]) => (
            <React.Fragment key={productId}>
              <TableRow>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => toggleProduct(productId)}
                  >
                    {openProducts[productId] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                  {variations[0].itemName}
                </TableCell>
                <TableCell>{variations[0].sku}</TableCell>
                <TableCell>${variations[0].price}</TableCell>
                <TableCell>{variations[0].stockLevel}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} style={{ padding: 0 }}>
                  <Collapse
                    in={openProducts[productId]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box margin={1}>
                      <Typography variant="subtitle1">Variations:</Typography>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Variation Name</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {variations.map((variation) => (
                            <TableRow key={variation.variationId}>
                              <TableCell>{variation.variationName}</TableCell>
                              <TableCell>{variation.sku}</TableCell>
                              <TableCell>${variation.price}</TableCell>
                              <TableCell>
                                <IconButton
                                  color="primary"
                                  onClick={() => onProductSelect(variation)}
                                >
                                  <InfoIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ProductList;
