import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Typography, Box } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getFormattedMarginText } from './marginUtils';

const CategoryList = ({ categories, onCategoryClick }) => {
  const sortedCategories = [...categories].sort((a, b) => 
    a.name.localeCompare(b.name)
  );

  return (
    <List>
      {sortedCategories.map((category) => (
        <ListItem
          button
          key={category.squareId}
          onClick={() => onCategoryClick(category)}
          sx={{
            padding: '16px',
            marginBottom: '8px',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#f0f0f0'
            }
          }}
        >
          <ListItemIcon>
            <CategoryIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h6">
                {category.name}
              </Typography>
            }
            secondary={
              <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                {category.marginRanges.length > 0 ? (
                  <Typography variant="body2" color="textSecondary">
                    Margins: {getFormattedMarginText(category.marginRanges)}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No margin range set
                  </Typography>
                )}
              </Box>
            }
          />
          <ArrowForwardIosIcon color="action" />
        </ListItem>
      ))}
    </List>
  );
};

export default CategoryList;