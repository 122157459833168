import { AppBar, Toolbar, Typography, IconButton, Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const InvoiceAppBar = ({ saveStatus, lastSaved, hasUnsavedChanges, handleSaveInvoice, isLoading }) => (
  <AppBar position="static" color="default" elevation={0}>
    <Toolbar variant="dense">
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Invoice Details
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
        {saveStatus && (
          <Typography variant="caption" color="textSecondary" sx={{ mr: 2 }}>
            {saveStatus}
          </Typography>
        )}
        {lastSaved && (
          <Typography variant="caption" color="textSecondary">
            Last saved: {lastSaved}
          </Typography>
        )}
      </Box>
      {hasUnsavedChanges && (
        <IconButton
          color="primary"
          onClick={handleSaveInvoice}
          disabled={isLoading}
          title="Save changes"
        >
          <SaveIcon />
        </IconButton>
      )}
    </Toolbar>
  </AppBar>
);

export default InvoiceAppBar;
