import { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import createApi from '../api';

export const useInvoiceLogic = (initialInvoices = []) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser?.stsTokenManager?.accessToken]);

  // Ensure invoices is always an array
  const invoices = Array.isArray(initialInvoices) ? initialInvoices : [];

  // State
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('invoiceDate');

  // Handlers
  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event?.target?.value || '');
  }, []);

  const handleFilterChange = useCallback((event) => {
    setFilterStatus(event?.target?.value || 'all');
  }, []);

  const handleSort = useCallback((field) => {
    setSortField((prevField) => {
      if (prevField === field) {
        setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortDirection('asc');
      }
      return field;
    });
  }, []);

  const handleRowClick = useCallback((invoice) => {
    if (invoice?.doc_id) {
      api.updateInvoiceStatus(invoice.doc_id, 'pending');
      navigate(`/dashboard/invoices/${invoice.doc_id}`);
    }
  }, [api, navigate]);

  // Filtered Data
  const filteredInvoices = useMemo(() => {
    const lowerCaseQuery = (searchQuery || '').toLowerCase();

    return invoices.filter((invoice) => {
      if (!invoice) return false;

      const statusFilter = filterStatus === 'all' || invoice.invoiceStatus === filterStatus;

      const matchesQuery = !lowerCaseQuery || [
        invoice.vendorName,
        invoice.invoiceNumber,
        invoice.invoiceDate && new Date(invoice.invoiceDate).toLocaleDateString('en-US'),
      ]
        .filter(Boolean)
        .some(field => 
          field?.toString().toLowerCase().includes(lowerCaseQuery)
        );

      return statusFilter && matchesQuery;
    });
  }, [invoices, searchQuery, filterStatus]);

  // Sorted Data
  const sortedInvoices = useMemo(() => {
    return [...filteredInvoices].sort((a, b) => {
      if (!a || !b) return 0;

      try {
        if (sortField === 'invoiceDate') {
          const dateA = new Date(a.invoiceDate || 0);
          const dateB = new Date(b.invoiceDate || 0);
          return sortDirection === 'asc' 
            ? dateA - dateB 
            : dateB - dateA;
        }

        const valueA = a[sortField] || '';
        const valueB = b[sortField] || '';
        
        if (sortDirection === 'asc') {
          return valueA.toString().localeCompare(valueB.toString());
        }
        return valueB.toString().localeCompare(valueA.toString());
      } catch (error) {
        console.error('Sorting error:', error);
        return 0;
      }
    });
  }, [filteredInvoices, sortField, sortDirection]);

  // Summary calculations with safe defaults
  const summaryData = useMemo(() => {
    return {
      totalValue: sortedInvoices.reduce((sum, inv) => 
        sum + (parseFloat(inv?.invoiceTotal || 0) || 0), 0
      ),
      pendingCount: sortedInvoices.filter(inv => 
        inv?.invoiceStatus === 'pending'
      ).length,
      totalCount: sortedInvoices.length || 0
    };
  }, [sortedInvoices]);

  return {
    // State
    searchQuery: searchQuery || '',
    filterStatus: filterStatus || 'all',
    
    // Filtered data
    filteredData: {
      sortField,
      sortDirection,
      paginatedInvoices: sortedInvoices || [],
      sortedInvoices: sortedInvoices || [],
      summary: summaryData
    },

    // Handlers
    handlers: {
      handleSearchChange,
      handleFilterChange,
      handleSort,
      handleRowClick,
    }
  };
};